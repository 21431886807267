import React, { useEffect } from "react";

import AddCategory from "./AddCategory";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AuthService from "./auth/AuthService";
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteConfirmation from "./common/DeleteConfirmation";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Link from "@material-ui/core/Link";
import { Pagination } from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TextField } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import config from "../config";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Plus, Search as SearchIcon, Edit3 } from 'react-feather';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2.5),
    backgroundColor: '#E7EAF3',
  },
  btn: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    border: '#E7EAF3 1px solid',
    borderRadius: 12,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 0, 2),
    '& button': {
      marginLeft: theme.spacing(2),
      width: 160,
    }
  },
  searchInput: {
    '& input': {
      paddingTop: '5px',
      paddingBottom: '3px',
      marginTop: '0px',
    }
  },
  searchFlex: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    width: '60%',
    '@media (min-width:991px)': {
      width: '500px',
    },
    '@media (max-width:767px)': {
      width: '100%',
    }
  },
  table: {
    overflow: 'initial',
  },
  tableTitle: {
    '@media (max-width:767px)': {
      display: 'none',
    }
  },
  tableAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tableHeadCell: {
    whiteSpace: 'pre',
  },
  tableCell: {
    whiteSpace: 'pre',
  },
  actionBtn: {
    width: 30,
    height: 30,
    lineHeight: '40px',
    textAlign: 'center',
    backgroundColor: 'rgba(177, 79, 30, 0.10)',
    display: 'block',
    borderRadius: 5,
    '& svg': {
      color: theme.palette.textSecondary,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& svg': {
        color: theme.palette.primary.contrastText,
      },
    }
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    // '& .'
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: 25,
    marginBottom: 25,
  },
}));

export default function RolesList(props) {
  const classes = useStyles();
  const [roles, setRoles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [limit] = React.useState(15);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  
  const [searchText, setSearchText] = React.useState("");
  const Auth = new AuthService();

  useEffect(() => {
    Auth.fetch(`/api/roles/roles?searchText=${searchText}&page=${page}`)
      .then((res) => {
        if (res.ack === true) {
          setRoles(res.roles);
          setTotalPages(Math.ceil(res.total_count / limit));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  const Search = () => {
    Auth.fetch(`/api/roles/roles?searchText=${searchText}&page=${page}`)
      .then((res) => {
        if (res.ack === true) {
          setRoles(res.roles);
          setTotalPages(Math.ceil(res.total_count / limit));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  
  const handleChange = (event, value) => {
    setPage(value);
  };
  
  const deleteRole = (id, index) => {
    Auth.fetch("/api/roles/role", {
      method: "DELETE",
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => {
        if (res.ack === true) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.pageRoot}>
      <Typography variant="h1">Roles List</Typography>
      <Divider className={classes.divider} />
      <div className={classes.btn}>
        <Link href="roles/new" className='link-btn'>
          <Plus size={16} />
          Add Role
        </Link>
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.searchContainer}>
          <Typography variant="h3" className={classes.tableTitle}>Role List</Typography>
          <div className={classes.searchFlex}>
            <CssTextField
              variant="outlined"
              fullWidth
              id="text"
              placeholder="Search Role..."
              name="searchText"
              autoFocus
              value={searchText}
              onChange={handleSearch}
            />
            <Button
              className={classes.searchButton}
              variant="contained"
              color="primary"
              onClick={Search}
              disableElevation
              endIcon={<SearchIcon size={16} />}
            >
              Search
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} />
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>Role Name</TableCell>
                <TableCell align="right" className={classes.tableHeadCell}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell scope="row" className={classes.tableCell}>{row.title} </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    <div className={classes.tableAction}>
                      <Link href={"/roles/"+row.id} className={classes.actionBtn} color="textSecondary">
                        <Edit3 size={18} />
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* {isLoading && (
            <div className={classes.spinner}>
              <div style={{ position: "relative" }}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  className={classes.top}
                  classes={{
                    circle: classes.circle,
                  }}
                  size={40}
                  thickness={4}
                  {...props}
                />
              </div>
            </div>
          )} */}
        </TableContainer>
      </div>
      <div className={classes.pagination}>
        <Pagination
          shape="rounded"
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
