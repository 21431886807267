import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Monitor, Save, Trash2 } from 'react-feather';
import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import ConfirmationPopUp from "./common/ConfirmationPopUp";
import Dialog from "@material-ui/core/Dialog";
import Divider from '@material-ui/core/Divider';
import { Editor } from "react-draft-wysiwyg";
import Grid from "@material-ui/core/Grid";
import HeaderImage from "./HeaderImage";
import LogBox from "./LogBox";
import Message from "./common/Message";
import Slide from "@material-ui/core/Slide";
import SubImagesUpload from "./SubImagesUpload";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import _ from "underscore";
import clsx from 'clsx';
import config from "./../config";
import draftToHtml from "draftjs-to-html";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
      },
    },
  },
})(TextField);

const useStyles = createStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2.5),
    backgroundColor: '#E7EAF3',
  },
  blogForm: {
    marginTop: theme.spacing(4),
  },
  btnGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
    flexWrap: "wrap",
    '& > *': {
      marginLeft: theme.spacing(1),
    },
    '@media (max-width:576px)': {
      justifyContent: "flex-start",
      '& > *': {
        marginLeft: theme.spacing(0),
      },
      '& .MuiButtonBase-root': {
        width: "47.5%",
        marginBottom: theme.spacing(1),
        '&:nth-child(odd)': {
          marginRight: theme.spacing(1),
        },
        '&:nth-child(even)': {
          marginLeft: theme.spacing(1),
        },
      }
    }
  },
  icon: {
    borderRadius: 3,
    width: 18,
    height: 18,
    marginLeft: 2,
    position: "relative",
    top: -1,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#B14F1E',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#B14F1E',
    },
  },
  btnPreview: {
    backgroundColor: "#964B00",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#643200",
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddBlogClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Number(props.computedMatch.params.id)
        ? props.computedMatch.params.id
        : 0,
      open: false,
      title: "",
      slug: "",
      description: "",
      postingDate: new Date().toISOString().substring(0, 10),
      status: 0,
      categories: [],
      selectedCategories: [],
      descriptionError: "",
      titleError: "",
      postingDateError: "",
      loadMessage: "",
      type: "",
      editorState: EditorState.createEmpty(),
      editorStateHTML: "",
      hideGallery: false,
      parentBlog : 0,
      parentBlogs: [],
    };
    
    this.msgInterval = null;
    this.refSubImagesUpload = React.createRef();
    this.refHeaderImage = React.createRef();
    this.refLogBox = React.createRef();
    this.progressInterval = null;
    this.handleFilesList = this.handleFilesList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.msgLoader = this.msgLoader.bind(this);
    this.inProcess = this.inProcess.bind(this);
    this.setBoxMessage = this.setBoxMessage.bind(this);
    this.Auth = new AuthService();
  }

  setBoxMessage(msg) {
    this.refLogBox.current.pushMessage(msg);
  }

  inProcess(value) {
    if (value === 0) {
      this.setState(
        (state) => ({
          inProcess: (state.inProcess -= 1),
        }),
        () => {
          console.log(this.state.inProcess);
        }
      );
    } else {
      this.setState(
        (state) => ({
          inProcess: (state.inProcess += 1),
        }),
        () => {
          console.log(this.state.inProcess);
        }
      );
    }
  }

  handleFilesList() {
    this.msgLoader("Loading Blog...");
    console.log("inside handleFilesList ");
  }

  componentDidMount() {
    this.setState({ loader: true });
    this.handleFilesList();
    this.handleClickOpen();
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
      title: "",
      slug: "",
      description: "",
      editorState: EditorState.createEmpty(),
    });

    this.Auth.fetch(`/api/blogs/categories`)
      .then((res) => {
        if (res.ack === true) {
          this.setState(
            {
              categories: res.categories,
            },
            () => {
              if (this.state.id > 0) {
                this.Auth.fetch(`/api/blogs/blog?id=${this.state.id}`)
                  .then((res) => {
                    let that = this;
                    if (res.ack === true) {
                      if (res.blog.content) {
                        const parsedEditorState = res.blog.content;
                        const editorState1 = EditorState.push(
                          this.state.editorState,
                          convertFromRaw(parsedEditorState),
                          "update-state"
                        );
                        let currentContentAsHTML = draftToHtml(
                          res.blog.content
                        );
                        this.setState({
                          editorState: editorState1,
                          editorStateHTML: currentContentAsHTML,
                        });
                      }
                      this.setState({
                        title: res.blog.title,
                        slug: res.blog.slug,
                        description: res.blog.description,
                        postingDate: new Date(res.blog.posting_date).toISOString().substring(0, 10),
                        headerImage: res.blog.header_image,
                        selectedCategories: res.selected_categories,
                        parentBlog: res.blog.parent_blog,
                        hideGallery: res.blog.hide_gallery,
                        status: res.blog.status == 1 ? 1 : 0,
                      });
                      console.log("categories: ", this.state.categories);
                      console.log(
                        "selectedCategories: ",
                        this.state.selectedCategories
                      );
                      let cats = this.state.categories;
                      this.state.selectedCategories.map((selected) => {
                        cats = _.reject(cats, selected);
                      });

                      this.setState({
                        categories: cats,
                      });

                      console.log("cats:", cats);
                      this.refSubImagesUpload.current.resetForm();
                      this.refSubImagesUpload.current.handleFilesList(
                        JSON.parse(res.blog.images),
                        JSON.parse(res.blog.del_images)
                      );
                    }

                    this.Auth.fetch(`/api/blogs/parent-blogs?id=${this.state.id}`)
                      .then((res2) => {
                        if (res2.ack === true) {
                          this.setState({
                            parentBlogs: res2.parent_blogs,
                          });
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
        
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  msgLoader(msg, time = false) {
    clearInterval(this.msgInterval);
    this.setState({ loadMessage: msg });
    if (time) {
      this.msgInterval = setInterval(() => {
        this.setState({ loadMessage: "" });
      }, time);
    }
  }

  slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  handleChange = (e) => {
    if (e.target.name === "title")
      this.setState({
        title: e.target.value,
      });
    else if (e.target.name === "description")
    this.setState({
      description: e.target.value,
    });
    else if (e.target.name === "postingDate")
      this.setState({
        postingDate: e.target.value,
      });
    else if (e.target.name === "hideGallery")
      this.setState({
        hideGallery: e.target.checked,
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("inside handle submit");
    if (this.state.title === "") {
      this.setState({
        titleError: "Required",
      });
      return;
    } else {
      this.setState({
        titleError: "",
      });
    }
    if (this.state.description === "") {
      this.setState({
        descriptionError: "Required",
      });
      return;
    } else {
      this.setState({
        descriptionError: "",
      });
    }

    if (this.state.postingDate === "") {
      this.setState({
        postingDateError: "Required",
      });
      return;
    } else {
      this.setState({
        postingDateError: "",
      });
    }

    if (this.state.id > 0) {
      this.Auth.fetch("/api/blogs/blog", {
        method: "PUT",
        body: JSON.stringify({
          id: this.state.id,
          title: this.state.title,
          description: this.state.description,
          posting_date: this.state.postingDate,
          images: this.refSubImagesUpload.current.state.imageSorting,
          content: JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
          selected_categories: JSON.stringify(this.state.selectedCategories),
          parent_blog: this.state.parentBlog,
          hide_gallery: this.state.hideGallery
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            this.setState({
              type: "success",
              loadMessage: res.message,
            });
            // setTimeout(() => {
            //   window.location = "/blogs";
            // }, 1000);
          } else {
            this.setState({
              type: "error",
              loadMessage: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.Auth.fetch("/api/blogs/blog", {
        method: "POST",
        body: JSON.stringify({
          title: this.state.title,
          description: this.state.description,
          slug: this.slugify(this.state.title),
          posting_date: this.state.postingDate,
          content: JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
          selected_categories: JSON.stringify(this.state.selectedCategories),
          parentBlog: this.state.parentBlog
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            this.setState({
              type: "success",
              loadMessage: res.message,
            });
            if(res.record_id > 0)
            {
              setTimeout(() => {
                window.location = "/blogs/"+res.record_id;
              }, 1000);
            }
            else {
              setTimeout(() => {
                window.location = "/blogs";
              }, 1000);
            }
          } else {
            this.setState({
              type: "error",
              loadMessage: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState: editorState,
    });
  };

  handlePreview = () => {
    window.open(`${config.domain}/blog/p/${this.state.slug}`, "_blank");
  };

  handlePublish = () => {
    this.Auth.fetch("/api/blogs/blog-publish", {
      method: "PUT",
      body: JSON.stringify({
        id: this.state.id,
        status: this.state.status == 1 ? 0 : 1,
      }),
    })
      .then((res) => {
        if (res.ack === true) {
          this.setState({
            type: "success",
            loadMessage: res.message,
          });
          setTimeout(() => {
            window.location = "/blogs";
          }, 1000);
        } else {
          this.setState({
            type: "error",
            loadMessage: res.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.pageRoot}>
        <Typography variant="h1">{this.state.id > 0 ? 'Edit Blog' : 'Add Blog'}</Typography>
        <Divider className={classes.divider} />
        <form noValidate autoComplete="off" className={classes.blogForm}>
          <Grid container spacing={3} alignItems="center">
          <Grid item lg={4} md={6} sm={6} xs={12}>
              <CssTextField
                variant="outlined"
                fullWidth
                id="standard-basic"
                label="Blog Title"
                name="title"
                placeholder="Blog Title"
                disabled={this.state.id ? true : false}
                value={this.state.title}
                onChange={this.handleChange}
                required
                autoFocus
                error={this.state.titleError === "" ? false : true}
                helperText={this.state.titleError}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <CssTextField
                variant="outlined"
                fullWidth
                id="standard-basic"
                label="Description"
                name="description"
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleChange}
                required
                error={this.state.descriptionError === "" ? false : true}
                helperText={this.state.descriptionError}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                options={this.state.categories}
                getOptionLabel={(option) => option.title}
                value={this.state.selectedCategories}
                onChange={(event, newValue) => {
                  this.setState({
                    selectedCategories: newValue,
                  });
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    variant="outlined"
                    label="Categories"
                    placeholder="Select categories"
                  />
                )}
              />
            </Grid>
            {this.state.parentBlogs.length > 0 && 
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Autocomplete
                  id="parent-blogs"
                  defaultValue={this.state.parentBlogs.filter(blog => blog.id === this.state.parentBlog)[0]} 
                  onChange={(event, newValue) => {
                    this.setState({parentBlog: newValue ? newValue.id : ""
                    });
                  }}
                  options={this.state.parentBlogs}
                  getOptionLabel={(option) => option.title}
                  fullWidth
                  renderInput={(params) => <CssTextField {...params} label="Parent Blog" variant="outlined" />}
                />
              </Grid>
            }
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <CssTextField
                fullWidth
                id="standard-basic"
                label="Posting Date"
                variant="outlined"
                type="date"
                name="postingDate"
                value={this.state.postingDate}
                onChange={this.handleChange}
                required
                error={this.state.postingDateError === "" ? false : true}
                helperText={this.state.postingDateError}
              />
            </Grid>
            {this.state.parentBlogs.length > 0 &&
            <Grid item sm={4} xs={12}>
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Checkbox
                    icon={<span className={classes.icon} />} 
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} 
                    checked={this.state.hideGallery}
                    value={this.state.hideGallery}
                    onChange={this.handleChange}
                    name="hideGallery"
                    color="primary"
                  />
                }
                label="Hide Gallery"
              />
              </Grid>
            }
          {/* <div className={classes.section}>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <CssTextField
                  id="standard-basic"
                  label="Title"
                  name="title"
                  variant="outlined"
                  disabled={this.state.id ? true : false}
                  value={this.state.title}
                  onChange={this.handleChange}
                  required
                  autoFocus
                  error={this.state.titleError === "" ? false : true}
                  helperText={this.state.titleError}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={this.state.categories}
                  getOptionLabel={(option) => option.title}
                  value={this.state.selectedCategories}
                  onChange={(event, newValue) => {
                    this.setState({
                      selectedCategories: newValue,
                    });
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Categories"
                      placeholder="Select categories"
                      className={classes.textField}
                    />
                  )}
                />
              </Grid>
              {this.state.parentBlogs.length > 0 && 
                <Grid item xs={3}>
                  <Autocomplete
                    id="parent-blogs"
                    defaultValue={this.state.parentBlogs.filter(blog => blog.id == this.state.parentBlog)[0]} 
                    onChange={(event, newValue) => {
                      this.setState({parentBlog: newValue ? newValue.id : ""
                      });
                    }}
                    options={this.state.parentBlogs}
                    getOptionLabel={(option) => option.title}
                    style={{ width: "100%" }}
                    renderInput={(params) => <TextField {...params} label="Parent Blog" variant="outlined" />}
                  />
                </Grid>
              }
              <Grid item xs={2}>
                <TextField
                  id="standard-basic"
                  label="Posting Date"
                  variant="outlined"
                  type="date"
                  name="postingDate"
                  className={classes.textField}
                  value={this.state.postingDate}
                  onChange={this.handleChange}
                  required
                  error={this.state.postingDateError === "" ? false : true}
                  helperText={this.state.postingDateError}
                />
              </Grid>
              <Grid item xs={2}>
                
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.hideGallery}
                      value={this.state.hideGallery}
                      onChange={this.handleChange}
                      name="hideGallery"
                      color="primary"
                    />
                  }
                  label="Hide Gallery"
                  style={{"float": "right"}}
                />
                
              </Grid>
            </Grid>
          </div> */}

            <Grid item xs={12}>
              {this.state.slug.length > 0 && (
                <>
                  <HeaderImage
                    msgLoader={this.msgLoader}
                    id={this.state.id}
                    headerImage={this.state.headerImage}
                    prefix={this.state.slug + "/header-image/"}
                    urlPrefix={this.state.prefix}
                    config={config}
                    ref={this.refHeaderImage}
                    updateApi={"blog-header"}
                    bucket={config.blog_bucket}
                    bucket_name={config.blog_bucket_name}
                  />
                  <SubImagesUpload
                    id={this.state.id}
                    setBoxMessage={this.setBoxMessage}
                    inProcess={this.inProcess}
                    config={config}
                    msgLoader={this.msgLoader}
                    prefix={this.state.slug + "/gallery/"}
                    ref={this.refSubImagesUpload}
                    updateApi={"blog-images"}
                    bucket={config.blog_bucket}
                    bucket_name={config.blog_bucket_name}
                    
                  />
                  <Editor
                    className={classes.blogEditor}
                    editorState={this.state.editorState}
                    wrapperClassName={classes.blogContentWrapper}
                    editorClassName={classes.blogContentEditor}
                    onEditorStateChange={this.onEditorStateChange}
                  />
                </>
              )}
              <div className={classes.btnGroup}>
                {this.state.id > 0 && (
                  <>
                    <Button
                      variant="contained"
                      className={classes.btnPreview}
                      size="large"
                      disableElevation
                      onClick={this.handlePreview}
                      startIcon={<Monitor size={18} />}
                    >
                      Preview
                    </Button>
                    {this.state.status === 0 && 
                    <ConfirmationPopUp
                      title="Blog Publish Confirmation"
                      buttonText="Publish"
                      targetFtn={this.handlePublish}
                      message={"Are you sure to want to publish this blog?"}
                    />
                    }
                    {this.state.status === 1 && 
                    <ConfirmationPopUp
                      title="Blog Un Publish Confirmation"
                      buttonText="Un Publish"
                      targetFtn={this.handlePublish}
                      message={"Are you sure to want to un-publish this blog?"}
                    />
                    }
                  </>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  onClick={this.handleSubmit}
                  startIcon={<Save size={18} />}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                  onClick={() => {
                    window.location = "/blogs";
                  }}
                  startIcon={<Trash2 size={18} />}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
        <Message type={this.state.type} msg={this.state.loadMessage} />
        <LogBox ref={this.refLogBox} />
      </div>
    );
  }
}

export default withStyles(useStyles)(AddBlogClass);
