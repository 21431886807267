import { ArrowLeft, Save } from 'react-feather';
import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from '@material-ui/core/Divider';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from 'clsx';
import reorder from "./dnd/reorder";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2.5),
    backgroundColor: '#E7EAF3',
  },
  grid: {
    marginTop: theme.spacing(5),
  },
  gridCheckbox: {
    marginTop: theme.spacing(1),
  },
  checkboxGrid: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@media (max-width: 767px)': {
      marginTop: theme.spacing(1),
    },
  },
  checkboxCol: {
    flex: '0 0 20%',
    maxWidth: '20%',
    marginTop: theme.spacing(2),
    '@media (max-width: 767px)': {
      flex: '0 0 33.33333%',
      maxWidth: '33.33333%',
      marginTop: theme.spacing(1),
    },
    '@media (max-width: 576px)': {
      flex: '0 0 50%',
      maxWidth: '50%',
    }
  },
  icon: {
    borderRadius: 3,
    width: 18,
    height: 18,
    marginLeft: 2,
    position: "relative",
    top: -1,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#B14F1E',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#B14F1E',
    },
  },
  formControlLabel: {
    '& .MuiTypography-root': {
      opacity: 0.60,
      fontSize: '15px',
    }
  },
  btnGroup: {
    marginTop: theme.spacing(4),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    '@media (max-width:576px)': {
      width: '50%',
    }
  },
  cancelBtn: {
    marginRight: theme.spacing(1),
    '@media (max-width:576px)': {
      width: '50%',
    }
  },
}));

export default function AddRole(props) {
  const classes = useStyles();
  const [id, setId] = React.useState(
    Number(props.computedMatch.params.id) ? props.computedMatch.params.id : 0
  );
  
  const [title, setTitle] = React.useState("");
  const [blogs, setBlogs] = React.useState("");
  const [promotions, setPromotions] = React.useState("");
  const [categories, setCategories] = React.useState("");
  const [users, setUsers] = React.useState("");
  const [superAdmin, setSuperAdmin] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(true);
  const Auth = new AuthService();

  useEffect(() => {
    Auth.fetch(`/api/roles/role?id=${id}`)
      .then((res) => {
        if (res.ack === true) {
          setTitle(res.role.title);
          setBlogs(res.role.blogs);
          setPromotions(res.role.promotions);
          setCategories(res.role.categories);
          setUsers(res.role.users);
          setSuperAdmin(res.role.super_admin);

          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {
    
    setIsLoading(true);
    if(id > 0)
    {
      Auth.fetch("/api/roles/role", {
        method: "PUT",
        body: JSON.stringify({
        title: title,
        blogs: blogs,
        promotions: promotions,
        categories: categories,
        users: users,
        super_admin: superAdmin,
        id: id,
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            
            window.location="/roles";
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
    Auth.fetch("/api/roles/role", {
      method: "POST",
      body: JSON.stringify({
      title: title,
      blogs: blogs,
      promotions: promotions,
      categories: categories,
      users: users,
      super_admin: superAdmin,
      }),
    })
      .then((res) => {
        if (res.ack === true) {
          window.location="/roles";
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const handleCancel = () =>{
    window.location = "/roles";
  }

  const handleChange = (e) => {
    setTitle(e.target.value)
  }

  return (
    <div className={classes.pageRoot}>
      <Typography variant="h1">{id > 0 ? "Update": "Add" } Role</Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} alignItems="center">
        <Grid item lg={6} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="standard-basic"
            label="Role Name"
            name="roleName"
            placeholder="Role Name"
            disabled={false}
            value={title}
            autoFocus
            error={false}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <div className={classes.checkboxGrid}>
        <div className={classes.checkboxCol}>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox 
                value={blogs} 
                color="primary" 
                onChange={(e)=>{
                  setBlogs(e.target.value === "1" ? 0 : 1);
                }}
                checked={blogs === 1 ? true : false}
                icon={<span className={classes.icon} />} 
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} 
              />}
            label="Blog"
          />
        </div>
        <div className={classes.checkboxCol}>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox 
                color="primary" 
                value={promotions}
                onChange={(e)=>{
                  setPromotions(e.target.value === "1" ? 0 : 1);
                }}
                checked={promotions ===1 ? true : false}
                icon={<span className={classes.icon} />} 
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} 
              />}
            label="Promotion"
          />
        </div>
        <div className={classes.checkboxCol}>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox 
                value={categories} 
                color="primary" 
                onChange={(e)=>{
                  setCategories(e.target.value === "1" ? 0 : 1);
                }}
                checked={categories ===1 ? true : false}
                icon={<span className={classes.icon} />} 
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} 
              />}
            label="Category"
          />
        </div>
        <div className={classes.checkboxCol}>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox 
                value={users} 
                color="primary"
                onChange={(e)=>{
                  setUsers(e.target.value === "1" ? 0 : 1);
                }}
                checked={users ===1 ? true : false}
                icon={<span className={classes.icon} />} 
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} 
              />}
            label="User"
          />
        </div>
        <div className={classes.checkboxCol}>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox 
                value={superAdmin} 
                color="primary" 
                onChange={(e)=>{
                  setSuperAdmin(e.target.value === "1" ? 0 : 1);
                }}
                checked={superAdmin ===1 ? true : false}
                icon={<span className={classes.icon} />} 
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} 
              />}
            label="Global Admin"
          />
        </div>
      </div>
      <div className={classes.btnGroup}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          disableElevation
          startIcon={<ArrowLeft size={18} />}
          className={classes.cancelBtn}
          onClick={handleCancel}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disableElevation
          onClick={handleSubmit}
          startIcon={<Save size={18} />}
          className={classes.saveBtn}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
