import { Button, TextField } from "@material-ui/core";
import { Check, Edit3, Eye, Plus, Search as SearchIcon, Trash2 } from 'react-feather';
import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteConfirmation from "./common/DeleteConfirmation";
import Divider from '@material-ui/core/Divider';
import Link from "@material-ui/core/Link";
import { Pagination } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import config from "./../config";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2.5),
    backgroundColor: '#E7EAF3',
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    border: '#E7EAF3 1px solid',
    borderRadius: 12,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 0, 2),
    '& button': {
      marginLeft: theme.spacing(2),
      width: 160,
    }
  },
  searchField: {
    '& .MuiInputBase-input': {
      height: '10px',
    }
  },
  searchButton: {
    height: '45px',
  },
  searchFlex: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    width: '60%',
    '@media (min-width:991px)': {
      width: '500px',
    },
    '@media (max-width:767px)': {
      width: '100%',
    }
  },
  table: {
    overflow: 'initial',
  },
  tableTitle: {
    '@media (max-width:767px)': {
      display: 'none',
    }
  },
  tableAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tableHeadCell:{
    whiteSpace: 'pre',
  },
  tableCell:{
    whiteSpace: 'pre',
  },
  btn: {
    height: 30,
    lineHeight: '30px',
    textAlign: 'center',
    backgroundColor: 'rgba(177, 79, 30, 0.10)',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1.2),
    paddingRight: theme.spacing(1.2),
    borderRadius: 5,
    transition: 'all 0.3s',
    '& svg': {
      color: theme.palette.textSecondary,
      // marginRight: theme.spacing(.8),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textDecoration: 'none',
      '& svg': {
        color: theme.palette.primary.contrastText,
      },
    }
  },
  approveBtn: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    '& svg': {
      marginRight: theme.spacing(.8),
    },
    cursor: "pointer"
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    // '& .'
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: 25,
    marginBottom: 25,
  },
}));

export default function Blogs(props) {
  const classes = useStyles();
  const [blogs, setBlogs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [searchText, setSearchText] = React.useState("");
  
  const [limit] = React.useState(15);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  
  const Auth = new AuthService();

  useEffect(() => {
    Auth.fetch(`/api/blogs/blogs?searchText=${searchText}&page=${page}` )
      .then((res) => {
        if (res.ack === true) {
          setBlogs(res.blogs);
          setTotalPages(Math.ceil(res.total_count / limit));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  
  const Search = () => {
    Auth.fetch(`/api/blogs/blogs?searchText=${searchText}&page=${1}`)
      .then((res) => {
        if (res.ack === true) {
          setBlogs(res.blogs);
          setTotalPages(Math.ceil(res.total_count / limit));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  
  const handleChange = (event, value) => {
    setPage(value);
  };
  
  const updateBlogStatus = (id, approved) => {
    console.log('inside ')
    Auth.fetch("/api/blogs/blog-approve", {
      method: "PUT",
      body: JSON.stringify({
        id: id,
        approved: approved,
      }),
    })
      .then((res) => {
        if (res.ack === true) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.blogListContainer}>
      <Typography variant="h1">Admin Blogs</Typography>
      <Divider className={classes.divider} />
      <div className={classes.tableContainer}>
        <div className={classes.searchContainer}>
          <Typography variant="h3" className={classes.tableTitle}>Blogs</Typography>
          <div className={classes.searchFlex}>
            <CssTextField
              className={classes.searchField}
              variant="outlined"
              fullWidth
              id="email"
              placeholder="Search Blog..."
              name="searchText"
              autoComplete="email"
              autoFocus
              value={searchText}
              onChange={handleSearch}
            />
            <Button
              className={classes.searchButton}
              variant="contained"
              color="primary"
              onClick={Search}
              disableElevation
              endIcon={<SearchIcon size={16} />}
            >
              Search
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} />
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>Title</TableCell>
                <TableCell className={classes.tableHeadCell}>Slug</TableCell>
                <TableCell className={classes.tableHeadCell}>Status</TableCell>
                <TableCell align="right" className={classes.tableHeadCell}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blogs.map((row, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell scope="row" className={classes.tableCell}>
                    {row.title}
                  </TableCell>
                  <TableCell scope="row" className={classes.tableCell}>
                    {row.slug}
                  </TableCell>
                  <TableCell scope="row" className={classes.tableCell}>
                    {row.approved === 1 ? "Approved" : "Not Approved"}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {/* <DeleteConfirmation deleteApi="/api/blogs/blog" id={row.id} /> */}
                    <div className={classes.tableAction}>
                      {row.approved === 1 && <div className={`${classes.approveBtn} ${classes.btn}`} color="textSecondary" onClick={()=> {updateBlogStatus(row.id, 0)}}>
                        <Trash2 size={14} />
                        <span>Decline</span>
                      </div>
                      }
                      {row.approved === 0 && 
                      <div className={`${classes.approveBtn} ${classes.btn}`} color="textSecondary" onClick={()=> {updateBlogStatus(row.id, 1)}}>
                        <Check size={16} />
                        <span>Approve</span>
                      </div>
                      }
                      <Link href={config.domain+"/blog/p/"+row.slug} target="_blank" className={`${classes.viewBtn} ${classes.btn}`} color="textSecondary">
                        <Eye size={14} />
                        {/* <span>View</span> */}
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isLoading && (
            <div className={classes.spinner}>
              <div style={{ position: "relative" }}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  className={classes.top}
                  classes={{
                    circle: classes.circle,
                  }}
                  size={40}
                  thickness={4}
                  {...props}
                />
              </div>
            </div>
          )}
        </TableContainer>
      </div>
      <div className={classes.pagination}>
        <Pagination
          shape="rounded"
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
