import React, { useEffect } from "react";
import { Save, Trash2 } from 'react-feather';
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from "@material-ui/core/Grid";
import HeaderImage from "./HeaderImage";
import InputLabel from "@material-ui/core/InputLabel";
import Message from "./common/Message";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import clsx from 'clsx';
import config from "./../config";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2.5),
    backgroundColor: '#E7EAF3',
  },
  blogForm: {
    marginTop: theme.spacing(4),
  },
  gridTop: {
    marginTop: theme.spacing(3),
  },
  gridCheckbox: {
    marginTop: theme.spacing(1),
  },
  checkboxGrid: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@media (max-width: 767px)': {
      marginTop: theme.spacing(1),
    },
  },
  checkboxCol: {
    '@media (max-width: 767px)': {
      flex: '0 0 33.33333%',
      maxWidth: '33.33333%',
      marginTop: theme.spacing(1),
    },
    '@media (max-width: 576px)': {
      flex: '0 0 50%',
      maxWidth: '50%',
    }
  },
  icon: {
    borderRadius: 3,
    width: 18,
    height: 18,
    marginLeft: 2,
    position: "relative",
    top: -1,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#B14F1E',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#B14F1E',
    },
  },
  btnGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
    flexWrap: "wrap",
    '& > *': {
      marginLeft: theme.spacing(1),
    },
    '@media (max-width:576px)': {
      justifyContent: "flex-start",
      '& > *': {
        marginLeft: theme.spacing(0),
      },
      '& .MuiButtonBase-root': {
        width: "47.5%",
        marginBottom: theme.spacing(1),
        '&:nth-child(odd)': {
          marginRight: theme.spacing(1),
        },
        '&:nth-child(even)': {
          marginLeft: theme.spacing(1),
        },
      }
    }
  },
  selectOptions: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E7EAF3!important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'blue',
      },
    },
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const categoryTypes = [
  { 'id': '1', 'value': 'Sedan' },
  { 'id': '2', 'value': 'Wagon' },
  { 'id': '3', 'value': 'Hatchback' }
];
export default function AddCategory(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(
    Number(props.computedMatch.params.id) ? props.computedMatch.params.id : 0
  );
  const [title, setTitle] = React.useState("");
  const [titleError, setTitleError] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [slugError, setSlugError] = React.useState("");
  const [isFeatured, setIsFeatured] = React.useState("");
  const [parentCategory, setParentCategory] = React.useState("");
  const [categoryType, setCategoryType] = React.useState('1');
  const [loadMessage, setLoadMessage] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [type, setType] = React.useState("");
  const [headerImage, setHeaderImage] = React.useState("");
  const [msgInterval, setMsgInterval] = React.useState("");

  const refHeaderImage = React.createRef();

  console.log("props, ", props);
  const Auth = new AuthService();

  useEffect(() => {
    setOpen(true);

    Auth.fetch("/api/blogs/categories")
      .then((res) => {
        if (res.ack === true) {
          setCategories(res.categories);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (id > 0) {
      Auth.fetch(`/api/blogs/category?id=${id}`)
        .then((res) => {
          if (res.ack === true) {
            setTitle(res.category.title);
            setSlug(res.category.slug);
            setIsFeatured(res.category.is_featured);
            setParentCategory(res.category.parent_id);
            setCategoryType(res.category.type);
            setHeaderImage(res.category.header_image);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "title") setTitle(e.target.value);
    else if (e.target.name === "slug") setSlug(e.target.value);
    else if (e.target.name === "parentCategory")
      setParentCategory(e.target.value);
    else if (e.target.name === "categoryType")
      setCategoryType(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (title === "") {
      setTitleError("Required");
      return;
    } else {
      setTitleError("");
    }

    if (slug === "") {
      setSlugError("Required");
      return;
    } else {
      setSlugError("");
    }

    if (id > 0) {
      Auth.fetch("/api/blogs/category", {
        method: "PUT",
        body: JSON.stringify({
          title: title,
          slug: slug,
          is_featured: isFeatured,
          parent_id: parentCategory,
          id: id,
          type: categoryType
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              window.location = "/categories";
            }, 1000);
          } else {
            setType("error");
            setLoadMessage(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Auth.fetch("/api/blogs/category", {
        method: "POST",
        body: JSON.stringify({
          title: title,
          slug: slug,
          is_featured: isFeatured,
          parent_id: parentCategory,
          type: categoryType
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              window.location = "/categories/" + res.record_id;
            }, 1000);
          } else {
            setType("error");
            setLoadMessage(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const msgLoader = (msg, time = false) => {
    clearInterval(msgInterval);

    setLoadMessage(msg);
    if (time) {
      setMsgInterval(setInterval(() => {
        setLoadMessage(loadMessage);
      }, time))
    }
  }


  return (
    <div className={classes.pageRoot}>
      <Typography variant="h1">{id > 0 ? 'Edit Category' : 'Add Category'}</Typography>
      <Divider className={classes.divider} />
      <form className={classes.blogForm} noValidate autoComplete="off">
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="standard-basic"
              variant="outlined"
              label="Title"
              value={title}
              name="title"
              fullWidth
              placeholder="Blog Title"
              onChange={handleChange}
              required
              error={titleError === "" ? false : true}
              helperText={titleError}
              autofocus={true}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="standard-basic"
              variant="outlined"
              label="Slug"
              value={slug}
              name="slug"
              fullWidth
              placeholder="Category Slug"
              onChange={handleChange}
              required
              error={slugError === "" ? false : true}
              helperText={slugError}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="parent-category">Parent Category</InputLabel>
              <Select
                className={classes.selectOptions}
                native
                value={parentCategory}
                label="Parent Category"
                onChange={handleChange}
                inputProps={{
                  name: "parentCategory",
                  id: "parent-category",
                }}
              >
                <option aria-label="None" value="" />
                {categories.map((cat) => {
                  let selected = parentCategory === cat.id ? " selected" : "";
                  if (cat.id !== id) {
                    return (
                      <option value={cat.id} selected>
                        {cat.title}
                      </option>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className={classes.checkboxGrid}>
              <div className={classes.checkboxCol}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  control={
                    <Checkbox
                      value={isFeatured} 
                      onChange={(e)=>{
                        setIsFeatured(e.target.value === "1" ? 0 : 1);
                      }}
                      checked={isFeatured === 1 ? true : false}
                      name="checkedA"
                      icon={<span className={classes.icon} />}
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    />
                  }
                  label="Is Featured"
                />
              </div>
            </div>
          </Grid>
        </Grid>
        {id > 0 &&
          <Grid item xs={12} className={classes.gridTop}>
            <HeaderImage
              msgLoader={msgLoader}
              id={id}
              headerImage={headerImage}
              prefix={"category-header-image/" + id + "/"}
              // urlPrefix={prefix}
              config={config}
              ref={refHeaderImage}
              updateApi={"category-header"}
            />
          </Grid>
        }
        <div className={classes.btnGroup}>
        <Button
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            onClick={() => {
              window.location = "/categories";
            }}
            startIcon={<Trash2 size={18} />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={handleSubmit}
            startIcon={<Save size={18} />}
          >
            Save
          </Button>
          
        </div>
      </form>
      <Message type={type} msg={loadMessage} />
    </div>
  );
}
