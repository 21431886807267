// @flow
import React, { Component } from "react";
import styled from "@emotion/styled";
import { colors } from "@atlaskit/theme";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Item from "./item";
import { grid } from "./constants";
import memoizeOne from "memoize-one";

const Wrapper = styled.div`
  background-color: ${({ isDraggingOver }) =>
    isDraggingOver ? colors.B50 : colors.N0};
  display: flex;
  flex-direction: column;
  padding: 0px;
  user-select: none;
  transition: background-color 0.1s ease;
  margin: 0px;
`;

const DropZone = styled.div`
  display: flex;
  /*
    Needed to avoid growth in list due to lifting the first item
    Caused by display: inline-flex strangeness
  */
  align-items: start;
  /* stop the list collapsing when empty */
  width: 100%;
  /* stop the list collapsing when it has no items */
  min-height: 60px;
`;

const ScrollContainer = styled.div`
  ${(props) => {
    // return (props.listId + ''.includes('gs') || props.listId + ''.includes('lnl')) ? 'border-bottom: solid;' : ''
  }}
  overflow: auto;
`;

const Container = styled.div`
  /* flex child */
  flex-grow: 1;
  /*
    flex parent
    needed to allow width to grow greater than body
  */
  display: inline-flex;
`;

const getSelectedMap = memoizeOne((selectedTaskIds) =>
  selectedTaskIds.reduce((previous, current) => {
    previous[current] = true;
    return previous;
  }, {})
);

export default class AuthorList extends Component {
  constructor(props) {
    super(props);
    // this.props.isCombineEnabled = false
    this.renderBoard = this.renderBoard.bind(this);
  }
  // static defaultProps = {
  //     isCombineEnabled: false,
  // };
  renderBoard(dropProvided) {
    const { tasks } = this.props;
    // const tasks = this.props.tasks;
    // console.log('selectedTaskIds', selectedTaskIds)
    const selectedTaskIds = this.props.selectedTaskIds;
    const draggingTaskId = this.props.draggingTaskId;
    // console.log('taskssssss', tasks)

    return (
      <Container className="row-outer">
        <DropZone className="row-container" ref={dropProvided.innerRef}>
          {tasks.map((task, index) => {
            // console.log('tasktasktasktask', task)
            const isSelected = Boolean(
              getSelectedMap(selectedTaskIds)[task.id]
            );
            const isGhosting =
              isSelected &&
              Boolean(draggingTaskId) &&
              draggingTaskId !== task.id;

            return (
              <Draggable key={task.id} draggableId={task.id} index={index}>
                {(dragProvided, dragSnapshot) => (
                  <Item
                    brand={this.props.brand}
                    removeImg={this.props.removeImg}
                    provided={dragProvided}
                    snapshot={dragSnapshot}
                    task={task}
                    index={index}
                    key={task.id}
                    isSelected={isSelected}
                    isGhosting={isGhosting}
                    selectionCount={selectedTaskIds.length}
                    toggleSelection={this.props.toggleSelection}
                    toggleSelectionInGroup={this.props.toggleSelectionInGroup}
                    multiSelectTo={this.props.multiSelectTo}
                  />
                )}
              </Draggable>
            );
          })}
          {dropProvided.placeholder}
        </DropZone>
      </Container>
    );
  }

  render() {
    const { listId, listType, internalScroll, isCombineEnabled } = this.props;

    return (
      <Droppable
        droppableId={listId + ""}
        type={listType}
        direction="horizontal"
        isCombineEnabled={isCombineEnabled}
      >
        {(dropProvided, dropSnapshot) => (
          <Wrapper
            isDraggingOver={dropSnapshot.isDraggingOver}
            {...dropProvided.droppableProps}
          >
            {internalScroll ? (
              <div>
                <ScrollContainer listId={listId}>
                  {this.renderBoard(dropProvided)}
                </ScrollContainer>
              </div>
            ) : (
              this.renderBoard(dropProvided)
            )}
          </Wrapper>
        )}
      </Droppable>
    );
  }
}
