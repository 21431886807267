import { ArrowLeft, Save } from 'react-feather';
import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Message from "./common/Message";
import Select from "@material-ui/core/Select";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const CssTextField = withStyles({
  root: {
    // border: '1px solid red',
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#E7EAF3',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2.5),
    backgroundColor: '#E7EAF3',
  },
  logo: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
  },
  img: {
    height: '45px',
  },
  grid: {
    marginTop: theme.spacing(1),
  },
  header: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  selectOptions: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E7EAF3!important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'blue',
      },
    },
  },
  btnGroup: {
    marginTop: theme.spacing(4),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    '@media (max-width:576px)': {
      width: '50%',
    }
  },
  cancelBtn: {
    marginRight: theme.spacing(1),
    '@media (max-width:576px)': {
      width: '50%',
    }
  }
}));

export default function UserDetails(props) {
  const classes = useStyles();
  const [id, setId] = React.useState(
    Number(props.computedMatch.params.id) ? props.computedMatch.params.id : 0
  );
  const [user, setUser] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [companyName, setCompanyName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [contactPerson, setContactPerson] = React.useState("");
  const [contactPersonMobile, setContactPersonMobile] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [tradeLicense, setTradeLicense] = React.useState("");
  const [passportcopy, setPassportcopy] = React.useState("");
  const [status, setStatus] = React.useState(0);
  const [roles, setRoles] = React.useState([]);
  const [role, setRole] = React.useState("");
  
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  
  const [isLoading, setIsLoading] = React.useState(true);
  const Auth = new AuthService();

  useEffect(() => {
    Auth.fetch(`/api/users/user?id=${id}`)
      .then((res) => {
        if (res.ack === true) {
          Auth.fetch(`/api/roles/roles`)
            .then((res1) => {
              if (res1.ack === true) {
                const tempRoles = res1.roles;
                const role = tempRoles.find(obj => obj.id == res.user.role);
                if(role)
                {
                  setRole(role.id);
                }
                setRoles(tempRoles);

                
                setUser(res.user);
                setFirstName(res.user.first_name);
                setLastName(res.user.last_name);
                setEmail(res.user.email);
                setStatus(res.user.status);
                // setRole(res.user.role);
                // setPassword(res.user.password);
                setCompanyName(res.user.company_name);
                setPhone(res.user.phone);
                setContactPerson(res.user.contact_person);
                setContactPersonMobile(res.user.contact_person_mobile);
                setLocation(res.user.location);
                setTradeLicense(res.user.trade_license);
                setPassportcopy(res.user.passport_copy);
                
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {

    setIsLoading(true);
    setLoadMessage("");
    Auth.fetch("/api/users/user", {
      method: "PUT",
      body: JSON.stringify({
        id: id,
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        status: status,
        role: role,
        company_name: companyName,
        phone: phone,
        contact_person: contactPerson,
        contact_person_mobile: contactPersonMobile,
        location: location,
        trade_license: tradeLicense,
        passport_copy: passportcopy,
      }),
    })
      .then((res) => {
        if (res.ack === true) {
          
          setType("success");
          setLoadMessage(res.message);
          setTimeout(() => {
            window.location = "/users";
          }, 1000);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const handleCancel = () => {
    window.location = "/users";
  }

  return (
    <div className={classes.pageRoot}>
      <Typography variant="h1">Users Detail</Typography>
      <Divider className={classes.divider} />
      <div className={classes.logo}>
        <img className={classes.img} src="../../qazai-wheels.svg" alt="Logo" />
      </div>
      <Grid container spacing={3} alignItems="center">
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            placeholder="First Name"
            onChange={(e)=> {
              setFirstName(e.target.value);
            }}
            value={firstName}
            autoFocus
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
            onChange={(e)=> {
              setLastName(e.target.value);
            }}
            value={lastName}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="email"
            label="Email"
            name="email"
            placeholder="Email"
            onChange={(e)=> {
              setEmail(e.target.value);
            }}
            value={email}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="password"
            label="Password"
            name="password"
            placeholder="Password"
            value={password}
            disabled={true}
            onChange={(e)=> {
              setPassword(e.target.value);
            }}
            type="password"
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="companyName"
            label="Company Name"
            name="companyName"
            value={companyName}
            placeholder="Company Name"
            onChange={(e)=> {
              setCompanyName(e.target.value);
            }}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="phone"
            label="Tel No"
            name="telNo"
            placeholder="Tel No"
            value={phone}
            onChange={(e)=> {
              setPhone(e.target.value);
            }}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="contactPersonName"
            label="Contact Person Name"
            name="contactPersonName"
            placeholder="Contact Person Name"
            value={contactPerson}
            onChange={(e)=> {
              setContactPerson(e.target.value);
            }}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="contactPerosnMobile"
            label="Contact Person Mobile"
            name="contactPerosnMobile"
            placeholder="Contact Person Mobile"
            value={contactPersonMobile}
            onChange={(e)=> {
              setContactPersonMobile(e.target.value);
            }}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="location"
            label="Location/Address"
            name="location"
            placeholder="Location/Address"
            value={location}
            onChange={(e)=> {
              setLocation(e.target.value);
            }}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="tradeLicense"
            label="Trade License"
            name="location"
            placeholder="Trade License"
            value={tradeLicense}
            onChange={(e)=> {
              setTradeLicense(e.target.value);
            }}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <CssTextField
            variant="outlined"
            fullWidth
            id="location"
            label="Passport and Visa Copy"
            name="location"
            placeholder="Passport and Visa Copy"
            value={passportcopy}
            onChange={(e)=> {
              setPassportcopy(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.header}>
        <Typography variant="h1">Admin Section</Typography>
        <Divider className={classes.divider} />
      </div>
      <Grid container spacing={3} alignItems="center">
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="parent-category">Select Status</InputLabel>
            <Select
              className={classes.selectOptions}
              native
              value={status}
              onChange={(e)=>{
                setStatus(e.target.value);
              }}
              label="Select Status"
              inputProps={{
                name: "selectStatus",
                id: "selectStatus",
              }}
            >
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12} className={classes.grid}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="parent-category">Select Role</InputLabel>
            <Select
              className={classes.selectOptions}
              native
              value={role}
              onChange={(e)=>{
                setRole(e.target.value);
              }}
              label="Select Role"
              inputProps={{
                name: "selectRole",
                id: "selectRole",
              }}
            >
              {/* <option value="1">Admin</option>
              <option value="2">User</option> */}
              {roles.map(option => {
                      return (
                        // <MenuItem key={option.id} value={option.job_id}>
                        //   {option.job_name}
                        // </MenuItem>
                        <option value={option.id}>{option.title}</option>
                      )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.btnGroup}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          disableElevation
          onClick={handleCancel}
          startIcon={<ArrowLeft size={18} />}
          className={classes.cancelBtn}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disableElevation
          startIcon={<Save size={18} />}
          className={classes.saveBtn}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
      
      <Message type={type} msg={loadMessage} />
    </div>
  );
}
