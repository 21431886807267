// import AuthService from "../auth/AuthService";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import { Send } from 'react-feather';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationPopUp(props) {

  const [open, setOpen] = React.useState(false);

  // const Auth = new AuthService();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className="btn-publish"
        variant="contained"
        size="large"
        color="success"
        disableElevation
        onClick={handleClickOpen}
        startIcon={<Send size={18} />}
      >
        {props.buttonText}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Typography variant="h1">{props.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained" disableElevation>
            Disagree
          </Button>
          <Button onClick={props.targetFtn} color="primary" variant="contained" disableElevation>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
