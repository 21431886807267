import React, { Component } from "react";

import AuthService from "./auth/AuthService";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import { Upload } from 'react-feather';
import qs from "query-string";
import serverlesslink from "./../config";
import { withStyles } from "@material-ui/core/styles";

const useStyles = (theme) => ({
  section: {
    backgroundColor: theme.palette.primary.contrastText,
    border: "1px solid #E7EAF3",
    borderRadius: 12,
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 20,
    paddingRight: 20,
  },
  sectionHeader: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 20,
  },
  divider: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
    backgroundColor: '#E7EAF3',
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '@media (max-width:576px)': {
      minWidth: '45px',
      height: '45px',
    },
    '& i': {
      fontStyle: 'normal',
      position: 'relative',
      top: 1,
      marginLeft: theme.spacing(1),
      '@media (max-width:576px)': {
        display: 'none',
      }
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
    }
  },
  headerImg: {
    maxWidth: "100%",
    display: "block",
    height: 300,
    borderRadius: 12,
    objectFit: "cover",
  },
});

class HeaderImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: "",
    };
    this.handleSubmitImage = this.handleSubmitImage.bind(this);
    this.Auth = new AuthService();
  }

  resetPreview() {
    this.setState({
      preview: "",
    });
  }

  // get_wasabi_s3 () {
  //   return new AWS.S3({
  //     // endpoint: new AWS.Endpoint(this.config.endpoint),
  //     accessKeyId: this.props.config.awsAccessKeyId,
  //     secretAccessKey: this.props.config.awsSecretAccessKey
  //   });
  // }

  handleSubmitImage(e) {
    e.preventDefault();
    this.props.msgLoader("Uploading Header image.");
    // var wasabi_s3 = this.get_wasabi_s3();

    let file = e.target.files[0];

    let that = this;
    let fileName = file.name.replace(".JPG", ".jpg");
    
    // if (!fileName.match(/.(jpg)$/i)) {
    //   that.props.msgLoader(`Only .jpg files are allowed`, 2000);
    // } else 
    {
      let params = {
        bucket: this.props.bucket_name,
        module_type: this.props.module,
        ContentType: file.type,
      };
      params.Key = this.props.prefix + fileName;

      this.setState({
        preview: URL.createObjectURL(file),
      });
      // let preview = URL.createObjectURL(file)

      /* WORKING */
      const name = params.Key.split("/").pop();

      fetch(
        `${serverlesslink.domain}/generate-put-url?${qs.stringify(params)}`,
        {
          headers: {
            "Content-Type": params.ContentType,
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          fetch(res.putURL, {
            method: "put",
            body: file,
            headers: {
              "Content-Type": params.ContentType,
            },
          })
            .then((response) => response)
            .then((data) => {
              if (data.status === 200) {
                that.Auth.fetch("/api/blogs/"+this.props.updateApi, {
                  method: "PUT",
                  body: JSON.stringify({
                    id: this.props.id,
                    header_image: fileName,
                  }),
                })
                  .then((data) => {
                    that.props.msgLoader("Header image uploaded.", 2000);
                  })
                  .catch((err) => {
                    console.log("error", err);
                  });
              }
            });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  render() {
    const { classes } = this.props;
    const { config, prefix, headerImage, bucket } = this.props;
    // const { bucket } = this.props.config

    return (
      <>
        <div className={classes.sectionHeader}>
          <Typography variant="h3">Upload Header Image</Typography>
          <Button variant="contained" color="primary" component="label" disableElevation className={classes.uploadBtn}>
            <Upload size={16} />
            <i>Upload Header Image</i>
            <input type="file" hidden onChange={this.handleSubmitImage} accept="image/*" />
          </Button>
          {/* <div>
            <label for="headImage" className={classes.fileUpload}>
              <CloudUploadIcon className={classes.uploadIcon} />
              Upload Header Image
            </label>
            <input
              type="file"
              id="headImage"
              onChange={this.handleSubmitImage}
              className={classes.uploadBtn}
              accept="image/*"
            />
          </div> */}
        </div>
        <Divider className={classes.divider} />
        <div className={classes.section}>
          <div id="result">
            <img
              className={classes.headerImg}
              style={{ display: this.state.preview === "" ? "none" : "block" }}
              src={this.state.preview}
              alt=""
            />
            {!(headerImage === "" || headerImage === null) ? (
              <img
                className={classes.headerImg}
                style={{
                  display: this.state.preview !== "" ? "none" : "block",
                }}
                src={this.props.bucket + prefix + headerImage}
                alt=""
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(useStyles)(HeaderImage);
