import React, { useEffect } from "react";
import { Rewind, Save, Trash2 } from 'react-feather';
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import Message from "./common/Message";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2.5),
    backgroundColor: '#E7EAF3',
  },
  subHeading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
    }
  },
  grid: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    }
  },
  selectOptions: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E7EAF3!important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'blue',
      },
    },
  },
  btnGroup: {
    marginTop: theme.spacing(4),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    '@media (max-width:576px)': {
      width: '50%',
    }
  },
  cancelBtn: {
    marginRight: theme.spacing(1),
    '@media (max-width:576px)': {
      width: '50%',
    }
  }
}));

export default function BlogPositions(props) {
  const classes = useStyles();

  const [blogs, setBlogs] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  const [blogCount, setBlogCount] = React.useState('one');
  const [blogPosition1, setBlogPosition1] = React.useState({ "id": "0", "title": "" });
  const [blogPosition2, setBlogPosition2] = React.useState({ "id": "0", "title": "" });
  const [blogPosition3, setBlogPosition3] = React.useState({ "id": "0", "title": "" });
  const [blogPosition4, setBlogPosition4] = React.useState({ "id": "0", "title": "" });

  const [categoryPosition1, setCategoryPosition1] = React.useState({ "id": "0", "title": "" });
  const [categoryPosition2, setCategoryPosition2] = React.useState({ "id": "0", "title": "" });
  const [categoryPosition3, setCategoryPosition3] = React.useState({ "id": "0", "title": "" });
  const [categoryPosition4, setCategoryPosition4] = React.useState({ "id": "0", "title": "" });
  const [categoryPosition5, setCategoryPosition5] = React.useState({ "id": "0", "title": "" });
  const [categoryPosition6, setCategoryPosition6] = React.useState({ "id": "0", "title": "" });
  const [categoryPosition7, setCategoryPosition7] = React.useState({ "id": "0", "title": "" });
  const [categoryPosition8, setCategoryPosition8] = React.useState({ "id": "0", "title": "" });

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");


  const [isLoading, setIsLoading] = React.useState(true);
  const Auth = new AuthService();

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#343A40',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'red',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#E7EAF3',
        },
        '&:hover fieldset': {
          borderColor: '#E7EAF3',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#E7EAF3',
        },
      },
    },
  })(TextField);

  useEffect(() => {
    Auth.fetch(`/api/blogs/all-blogs`)
      .then((res) => {
        if (res.ack === true) {
          const blogs = res.blogs;
          setBlogs(blogs);

          Auth.fetch(`/api/blogs/blogs-position`)
            .then((res1) => {
              if (res1.ack === true) {
                let position1 = res1.position.filter(blog => blog.position === 1);
                if (position1.length > 0) {
                  let blog1 = blogs.filter(blog => blog.id === position1[0]['blog_id']);
                  setBlogPosition1(blog1[0]);
                }

                let position2 = res1.position.filter(blog => blog.position === 2);
                if (position2.length > 0) {
                  let blog2 = blogs.filter(blog => blog.id === position2[0]['blog_id']);
                  setBlogPosition2(blog2[0]);
                }

                let position3 = res1.position.filter(blog => blog.position === 3);
                if (position3.length > 0) {
                  let blog3 = blogs.filter(blog => blog.id === position3[0]['blog_id']);
                  setBlogPosition3(blog3[0]);
                }

                let position4 = res1.position.filter(blog => blog.position === 4);
                if (position4.length > 0) {
                  let blog4 = blogs.filter(blog => blog.id === position4[0]['blog_id']);
                  setBlogPosition4(blog4[0]);
                }

                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // Get all categories
    Auth.fetch(`/api/blogs/featured-categories`)
      .then((res) => {
        if (res.ack === true) {
          const categories = res.categories;
          setCategories(categories);

          Auth.fetch(`/api/blogs/featured-categories-position`)
            .then((res1) => {
              if (res1.ack === true) {
                let position1 = res1.position.filter(cat => cat.position === 1);
                if (position1.length > 0) {
                  let cat1 = categories.filter(cat => cat.id === position1[0]['category_id']);
                  setCategoryPosition1(cat1[0]);
                }

                let position2 = res1.position.filter(cat => cat.position === 2);
                if (position2.length > 0) {
                  let cat2 = categories.filter(cat => cat.id === position2[0]['category_id']);
                  setCategoryPosition2(cat2[0]);
                }

                let position3 = res1.position.filter(cat => cat.position === 3);
                if (position3.length > 0) {
                  let cat3 = categories.filter(cat => cat.id === position3[0]['category_id']);
                  setCategoryPosition3(cat3[0]);
                }

                let position4 = res1.position.filter(cat => cat.position === 4);
                if (position4.length > 0) {
                  let cat4 = categories.filter(cat => cat.id === position4[0]['category_id']);
                  setCategoryPosition4(cat4[0]);
                }

                let position5 = res1.position.filter(cat => cat.position === 5);
                if (position5.length > 0) {
                  let cat5 = categories.filter(cat => cat.id === position5[0]['category_id']);
                  setCategoryPosition5(cat5[0]);
                }

                let position6 = res1.position.filter(cat => cat.position === 6);
                if (position6.length > 0) {
                  let cat6 = categories.filter(cat => cat.id === position6[0]['category_id']);
                  setCategoryPosition6(cat6[0]);
                }

                let position7 = res1.position.filter(cat => cat.position === 7);
                if (position7.length > 0) {
                  let cat7 = categories.filter(cat => cat.id === position7[0]['category_id']);
                  setCategoryPosition7(cat7[0]);
                }

                let position8 = res1.position.filter(cat => cat.position === 8);
                if (position8.length > 0) {
                  let cat8 = categories.filter(cat => cat.id === position8[0]['category_id']);
                  setCategoryPosition8(cat8[0]);
                }


                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {

    setIsLoading(true);
    setLoadMessage("");
    Auth.fetch("/api/blogs/save-blogs-categories-position", {
      method: "POST",
      body: JSON.stringify({
        blogPosition1: blogPosition1.id,
        blogPosition2: blogPosition2.id,
        blogPosition3: blogPosition3.id,
        blogPosition4: blogPosition4.id,

        categoryPosition1: categoryPosition1.id,
        categoryPosition2: categoryPosition2.id,
        categoryPosition3: categoryPosition3.id,
        categoryPosition4: categoryPosition4.id,
        categoryPosition5: categoryPosition5.id,
        categoryPosition6: categoryPosition6.id,
        categoryPosition7: categoryPosition7.id,
        categoryPosition8: categoryPosition8.id,
      }),
    })
      .then((res) => {
        if (res.ack === true) {

          setIsLoading(false);
          setLoadMessage(res.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    window.location = "/dashboard";
  }

  return (
    <div className={classes.pageRoot}>
      <Typography variant="h1">Positions</Typography>
      <Divider className={classes.divider} />
      <Typography variant="h3" className={classes.subHeading}>Featured Blog Positions</Typography>
      <Grid container spacing={3} alignItems="center">
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="positionOne"
            options={blogs}
            value={blogPosition1}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setBlogPosition1(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Blog position 1" variant="outlined" />}
          />
          {/* <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="positionOne">Position 1 {blogPosition1} </InputLabel>
            <Select
              className={classes.selectOptions}
              native
              value={blogPosition1}
              label="Position 1"
              onChange={(e) => {
                setBlogPosition1(e.target.value)
              }}
              inputProps={{
                name: "positionOne",
                id: "positionOne",
              }}
            >
              <MenuItem key={0} value={0}>Select Blog {blogPosition1} </MenuItem>
              {blogs.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="positionTwo"
            options={blogs}
            value={blogPosition2}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setBlogPosition2(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Blog position 2" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="positionThree"
            options={blogs}
            value={blogPosition3}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setBlogPosition3(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Blog position 3" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="positionFour"
            options={blogs}
            value={blogPosition4}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setBlogPosition4(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Blog position 4" variant="outlined" />}
          />
        </Grid>
      </Grid>
      <Typography variant="h3" className={classes.subHeading} style={{ marginTop: '30px' }}>Categories Positions</Typography>
      <Grid container spacing={3} alignItems="center">
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="catPositionOne"
            options={categories}
            value={categoryPosition1}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setCategoryPosition1(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Category position 1" variant="outlined" />}
          />
          {/* <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="catPositionOne">Position 1</InputLabel>
            <Select
              className={classes.selectOptions}
              native
              value={categoryPosition1}
              label="Position 1"
              onChange={(e) => {
                setCategoryPosition1(e.target.value)
              }}
              inputProps={{
                name: "catPositionOne",
                id: "catPositionOne",
              }}
            >
              <option key={0} value={0}>Select Category</option>
              {categories.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.title}
                </option>
              ))}
            </Select>
          </FormControl> */}
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="catPositionTwo"
            options={categories}
            value={categoryPosition2}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setCategoryPosition2(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Category position 2" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="catPositionThree"
            options={categories}
            value={categoryPosition3}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setCategoryPosition3(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Category position 3" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="catPositionFour"
            options={categories}
            value={categoryPosition4}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setCategoryPosition4(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Category position 4" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="catPositionFive"
            options={categories}
            value={categoryPosition5}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setCategoryPosition5(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Category position 5" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="catPositionSix"
            options={categories}
            value={categoryPosition6}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setCategoryPosition6(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Category position 6" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="catPositionSeven"
            options={categories}
            value={categoryPosition7}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setCategoryPosition7(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Category position 7" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.grid}>
          <Autocomplete
            id="catPositionEight"
            options={categories}
            value={categoryPosition8}
            getOptionLabel={(option) => option.title}
            onChange={(e, newValue) => {
              setCategoryPosition8(newValue);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Category position 8" variant="outlined" />}
          />
        </Grid>
      </Grid>
      <div className={classes.btnGroup}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          disableElevation
          onClick={handleCancel}
          startIcon={<Trash2 size={18} />}
          className={classes.cancelBtn}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disableElevation
          onClick={handleSubmit}
          startIcon={<Save size={18} />}
          className={classes.saveBtn}
        >
          Save
        </Button>
      </div>

      <Message type={type} msg={loadMessage} />
    </div>
  );
}
