import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./AuthService";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Message from "./../common/Message";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import clsx from 'clsx';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 60px)',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "520px",
    margin: theme.spacing(0, 'auto'),
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  logoImg: {
    height: 45,
    display: "block",
    margin: theme.spacing(0, 0, 4, 0),
  },
  auth: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: theme.spacing(5),
    border: "1px solid #E7EAF3",
    width: '100%',
    boxSizing: 'border-box',
  },
  authTitle: {
    margin: theme.spacing(0, 0, 2.5, 0),
  },
  subTitle: {
    margin: theme.spacing(0, 0, 2.5, 0),
  },
  gridText: {
    margin: theme.spacing(1.5, 0, 1, 0),
  },
  submit: {
    margin: theme.spacing(1.5, 0, 0, 0),
  },
  icon: {
    borderRadius: 3,
    width: 18,
    height: 18,
    marginLeft: 2,
    position: "relative",
    top: -1,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#B14F1E',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#B14F1E',
    },
  },
  formControlLabel: {
    '& .MuiTypography-root': {
      opacity: 0.60,
      fontSize: '15px',
    }
  },
  footer: {
    margin: theme.spacing(4, 0, 0, 0),
  }
}));

function Copyright() {
  const classes = useStyles();

  return (
    <Typography className={classes.footer} variant="caption" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
      Qizai Wheels
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn(props) {
  const classes = useStyles();

  const [error, setError] = useState(null);
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("info");

  const Auth = new AuthService();

  useEffect(() => {
    if (Auth.loggedIn()) props.history.replace("/dashboard");
  }, []);

  function handleSubmit(event) {
    event.preventDefault();

    let flg = false;
    if (email === "") {
      setEmailError("Email is Required");
      flg = true;
    } else {
      setEmailError("");
    }

    if (password === "") {
      setPasswordError("Password is Required");
      flg = true;
    } else {
      setPasswordError("");
    }

    if (flg) {
      return;
    }
    setLoadMessage("");
    Auth.login(email, password)
      .then((res) => {
        if (res.ack) {
          window.location = "/dashboard";
        } else {
          setType("error");
          setLoadMessage(res.message);
          setError(res.status);
        }
      })
      .catch((err) => {
        console.log(err);
        setType("error");
        setLoadMessage(err.message);
        setError(err.status);
      });
  }

  return (
    <div className={classes.root}>
      <img className={classes.logoImg} src="../../qazai-wheels.svg" alt="Qizai Wheel" />
      <div className={classes.auth}>
        <Typography variant="h1" color="textSecondary" align="center" className={classes.authTitle} gutterBottom>Sign In</Typography>
        <Typography color="textSecondary" variant="caption" display="block" align="center" className={classes.subTitle}>Enter your credentials to Sign in</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <CssTextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Your Email"
            placeholder="info@qizaiwheel.com"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            error={emailError === "" ? false : true}
            helperText={emailError}
          />
          <CssTextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="password"
            label="Password"
            type="password"
            placeholder="Enter Password"
            name="password"
            autoComplete="current-password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            error={passwordError === "" ? false : true}
            helperText={passwordError}
          />
          <Grid container alignItems="center" className={classes.gridText}>
            <Grid item xs={6}>
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Checkbox 
                    value="remember" 
                    color="primary" 
                    icon={<span className={classes.icon} />} 
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} 
                  />}
                label="Remember me"
              />
            </Grid>
            <Grid item xs={6} align="right">
              <Link href="#" variant="caption">Forgot password?</Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
        <Message type={type} msg={loadMessage} />
      </div>
      <Copyright />
    </div>
  );
}
