import { Button, TextField } from "@material-ui/core";
import { Edit3, Plus, Search as SearchIcon } from 'react-feather';
import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteConfirmation from "./common/DeleteConfirmation";
import Divider from '@material-ui/core/Divider';
import Link from "@material-ui/core/Link";
import { Pagination } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2.5),
    backgroundColor: '#E7EAF3',
  },
  btn: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    border: '#E7EAF3 1px solid',
    borderRadius: 12,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 0, 2),
    '& button': {
      marginLeft: theme.spacing(2),
      width: 160,
    }
  },
  searchInput: {
    '& input': {
      paddingTop: '5px',
      paddingBottom: '3px',
      marginTop: '0px',
    }
  },
  searchFlex: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    width: '60%',
    '@media (min-width:991px)': {
      width: '500px',
    },
    '@media (max-width:767px)': {
      width: '100%',
    }
  },
  table: {
    overflow: 'initial',
  },
  tableTitle: {
    '@media (max-width:767px)': {
      display: 'none',
    }
  },
  tableAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    width: 30,
    height: 30,
    lineHeight: '40px',
    textAlign: 'center',
    backgroundColor: 'rgba(177, 79, 30, 0.10)',
    display: 'block',
    borderRadius: 5,
    '& svg': {
      color: theme.palette.textSecondary,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& svg': {
        color: theme.palette.primary.contrastText,
      },
    }
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    // '& .'
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: 25,
    marginBottom: 25,
  },
}));

export default function Blogs(props) {
  const classes = useStyles();
  const [blogs, setBlogs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [searchText, setSearchText] = React.useState("");
  
  const [limit] = React.useState(15);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  
  const Auth = new AuthService();

  useEffect(() => {
    Auth.fetch(`/api/blogs/blogs?searchText=${searchText}&page=${page}` )
      .then((res) => {
        if (res.ack === true) {
          setBlogs(res.blogs);
          setTotalPages(Math.ceil(res.total_count / limit));
          setIsLoading(false);
        }
        else
        {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  
  const Search = () => {
    Auth.fetch(`/api/blogs/blogs?searchText=${searchText}&page=${1}`)
      .then((res) => {
        if (res.ack === true) {
          setBlogs(res.blogs);
          setTotalPages(Math.ceil(res.total_count / limit));
          setIsLoading(false);
        }
        else
        {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  
  const handleChange = (event, value) => {
    setPage(value);
  };
  
  const deleteBlog = (id, index) => {
    Auth.fetch("/api/blogs/blog", {
      method: "DELETE",
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => {
        if (res.ack === true) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.blogListContainer}>
      <Typography variant="h1">Blogs List</Typography>
      <Divider className={classes.divider} />
      <div className={classes.btn}>
        <Link href="blogs/new" className='link-btn'>
          <Plus size={16} />
          Add Blog
        </Link>
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.searchContainer}>
          <Typography variant="h3" className={classes.tableTitle}>Blogs</Typography>
          <div className={classes.searchFlex}>
            <CssTextField
              variant="outlined"
              fullWidth
              id="email"
              placeholder="Search Blog..."
              name="searchText"
              autoComplete="email"
              autoFocus
              value={searchText}
              onChange={handleSearch}
            />
            <Button
              className={classes.searchButton}
              variant="contained"
              color="primary"
              onClick={Search}
              disableElevation
              endIcon={<SearchIcon size={16} />}
            >
              Search
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} />
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>Title</TableCell>
                {/* <TableCell className={classes.tableHeadCell}>Slug</TableCell> */}
                <TableCell className={classes.tableHeadCell}>Published</TableCell>
                <TableCell className={classes.tableHeadCell}>Date</TableCell>
                <TableCell align="right" className={classes.tableHeadCell}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blogs.map((row, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell scope="row" className={classes.tableCell}>
                    {row.title}
                  </TableCell>
                  {/* <TableCell scope="row" className={classes.tableCell}>
                    {row.slug}
                  </TableCell> */}
                  <TableCell scope="row" className={classes.tableCell}>
                    {row.status === 1 ? "Yes": "No"}
                  </TableCell>
                  <TableCell scope="row" className={classes.tableCell}>
                    {new Date(row.posting_date).toISOString().substring(0, 10)}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {/* <DeleteConfirmation deleteApi="/api/blogs/blog" id={row.id} /> */}
                    <div className={classes.tableAction}>
                      <Link href={"blogs/" + row.id} className={classes.actionBtn} color="textSecondary">
                        <Edit3 size={18} />
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {!isLoading && blogs.length == 0 && <TableRow><TableCell>No records found !</TableCell></TableRow>}
            </TableBody>
          </Table>
          {isLoading && (
            <div className={classes.spinner}>
              <div style={{ position: "relative" }}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  className={classes.top}
                  classes={{
                    circle: classes.circle,
                  }}
                  size={40}
                  thickness={4}
                  {...props}
                />
              </div>
            </div>
          )}
        </TableContainer>
      </div>
      <div className={classes.pagination}>
        <Pagination
          shape="rounded"
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
