import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ArrowLeft, Save, Upload } from 'react-feather';
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Monitor, Trash2 } from 'react-feather';
import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import ConfirmationPopUp from "./common/ConfirmationPopUp";
import Dialog from "@material-ui/core/Dialog";
import Divider from '@material-ui/core/Divider';
import { Editor } from "react-draft-wysiwyg";
import Grid from "@material-ui/core/Grid";
import HeaderImage from "./HeaderImage";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LogBox from "./LogBox";
import Message from "./common/Message";
import ReactPlayer from 'react-player/youtube'
import Slide from "@material-ui/core/Slide";
import SubImagesUpload from "./SubImagesUpload";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import _ from "underscore";
import clsx from 'clsx';
import config from "./../config";
import draftToHtml from "draftjs-to-html";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
        borderWidth: '1px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
        borderWidth: '1px',
      },
    },
  },
})(TextField);

const useStyles = createStyles((theme) => ({
  section: {
    width: 490,
    height: '300px',
    '@media (max-width:576px)': {
      width: '100%',
    },
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 20,
  },
  subSectionHeader: {
    marginTop: 30,
  },
  divider: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
    backgroundColor: '#E7EAF3',
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '@media (max-width:576px)': {
      minWidth: '45px',
      height: '45px',
    },
    '& i': {
      fontStyle: 'normal',
      position: 'relative',
      top: 1,
      marginLeft: theme.spacing(1),
      '@media (max-width:576px)': {
        display: 'none',
      }
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
    }
  },
  headerImg: {
    maxWidth: "100%",
    display: "block",
    height: 300,
    borderRadius: 12,
    objectFit: "cover",
    '@media (max-width:576px)': {
      width: '100%',
      height: '100%',
    }
  },
  userMeta: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    border: '1px solid #E7EAF3',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 12,
    width: '100%',
    '@media (max-width:767px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    }
  },
  userLogo: {
    border: '1px solid #E7EAF3',
    padding: theme.spacing(4),
    borderRadius: 12,
    '& img': {
      display: 'block',
      height: '68px'
    }
  },
  list: {
    paddingLeft: theme.spacing(1.5),
    '@media (max-width:767px)': {
      paddingLeft: theme.spacing(0),
      '& .MuiListItem-gutters': {
        paddingLeft: theme.spacing(0),
      }
    },
    '& .MuiListItem-gutters': {
      paddingTop: theme.spacing(.2),
      paddingBottom: theme.spacing(.2),
    }
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '& .MuiListItemText-primary': {
      width: 220,
      fontSize: '14px',
      '@media (max-width:576px)': {
        width: '100%',
      }
    },
    '@media (max-width:576px)': {
      flexDirection: 'column',
      alignItems: 'start',
    }
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '50%',
    overflow: 'hidden',
    borderRadius: 12,
  },
  reactPlayer: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  btnGroup: {
    marginTop: theme.spacing(4),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    '@media (max-width:576px)': {
      width: '50%',
    }
  },
  cancelBtn: {
    marginRight: theme.spacing(1),
    '@media (max-width:576px)': {
      width: '50%',
    }
  },
  btnPreview: {
    backgroundColor: "#964B00",
    marginRight: "15px",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#643200",
    }
  }
}));

class AddPromotionClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Number(props.computedMatch.params.id)
        ? props.computedMatch.params.id
        : 0,
      open: false,
      title: "",
      slug: "",
      location: "",
      expiryDate: new Date().toISOString().substring(0, 10),
      editorState: EditorState.createEmpty(),
      editorStateHTML: "",
      video: "",
      videoError: "",
      status: 0,
      titleError: "",
      locationError: "",
      expiryDateError: "",
      loadMessage: "",
      type: "",
    };

    this.msgInterval = null;
    this.refSubImagesUpload = React.createRef();
    this.refHeaderImage = React.createRef();
    this.refLogBox = React.createRef();
    this.progressInterval = null;
    this.handleFilesList = this.handleFilesList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.msgLoader = this.msgLoader.bind(this);
    this.inProcess = this.inProcess.bind(this);
    this.setBoxMessage = this.setBoxMessage.bind(this);
    this.Auth = new AuthService();
  }

  setBoxMessage(msg) {
    this.refLogBox.current.pushMessage(msg);
  }

  inProcess(value) {
    if (value === 0) {
      this.setState(
        (state) => ({
          inProcess: (state.inProcess -= 1),
        }),
        () => {
          console.log(this.state.inProcess);
        }
      );
    } else {
      this.setState(
        (state) => ({
          inProcess: (state.inProcess += 1),
        }),
        () => {
          console.log(this.state.inProcess);
        }
      );
    }
  }

  handleFilesList() {
    this.msgLoader("Loading Promotion...");
    console.log("inside handleFilesList ");
  }

  componentDidMount() {
    this.setState({ loader: true });
    this.handleFilesList();
    this.handleClickOpen();
  }

  handlePreview = () => {
    window.open(`${config.domain}/promotions/${this.state.slug}`, "_blank");
  };
  
  handleClickOpen = () => {
    this.setState({
      open: true,
      title: "",
      slug: "",
      editorState: EditorState.createEmpty(),
    });

    if (this.state.id > 0) {
      this.Auth.fetch(`/api/common/promotion?id=${this.state.id}`)
        .then((res) => {
          let that = this;
          if (res.ack === true) {
            if (res.promotion.content) {
              const parsedEditorState = res.promotion.content;
              const editorState1 = EditorState.push(
                this.state.editorState,
                convertFromRaw(parsedEditorState),
                "update-state"
              );
              let currentContentAsHTML = draftToHtml(
                res.promotion.content
              );
              this.setState({
                editorState: editorState1,
                editorStateHTML: currentContentAsHTML,
              });
            }
            
            this.setState({
              title: res.promotion.title,
              slug: res.promotion.slug,
              location: res.promotion.location,
              video: res.promotion.video,
              expiryDate: new Date(res.promotion.expiry_date).toISOString().substring(0, 10),
              headerImage: res.promotion.header_image,
              status: res.promotion.status == 1 ? 1 : 0,
            });
            this.refSubImagesUpload.current.resetForm();
            this.refSubImagesUpload.current.handleFilesList(
              JSON.parse(res.promotion.gallery_images),
              JSON.parse(res.promotion.gallery_images)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  msgLoader(msg, time = false) {
    clearInterval(this.msgInterval);
    this.setState({ loadMessage: msg });
    if (time) {
      this.msgInterval = setInterval(() => {
        this.setState({ loadMessage: "" });
      }, time);
    }
  }

  slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  handleChange = (e) => {
    if (e.target.name === "title")
      this.setState({
        title: e.target.value,
      });
    else if (e.target.name === "expiryDate")
      this.setState({
        expiryDate: e.target.value,
      });
    else if (e.target.name === "hideGallery")
      this.setState({
        hideGallery: e.target.checked,
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("inside handle submit");
    if (this.state.title === "") {
      this.setState({
        titleError: "Required",
      });
      return;
    } else {
      this.setState({
        titleError: "",
      });
    }

    if (this.state.location === "") {
      this.setState({
        locationError: "Required",
      });
      return;
    } else {
      this.setState({
        locationError: "",
      });
    }

    if (this.state.expiryDate === "") {
      this.setState({
        expiryDateError: "Required",
      });
      return;
    } else {
      this.setState({
        expiryDateError: "",
      });
    }

    if (this.state.video === "") {
      this.setState({
        videoError: "Required",
      });
      return;
    } else {
      this.setState({
        videoError: "",
      });
    }

    if (this.state.id > 0) {
      this.Auth.fetch("/api/common/promotion", {
        method: "PUT",
        body: JSON.stringify({
          title: this.state.title,
          id: this.state.id,
          expiry_date: this.state.expiryDate,
          location: this.state.location,
          video: this.state.video,
          gallery_images: this.state.gallery_images,
          content: JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            this.setState({
              type: "success",
              loadMessage: res.message,
            });
            // setTimeout(() => {
            //   window.location = "/blogs";
            // }, 1000);
          } else {
            this.setState({
              type: "error",
              loadMessage: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.Auth.fetch("/api/common/promotion", {
        method: "POST",
        body: JSON.stringify({
          title: this.state.title,
          slug: this.slugify(this.state.title),
          expiry_date: this.state.expiryDate,
          location: this.state.location,
          video: this.state.video,
          content: JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            this.setState({
              type: "success",
              loadMessage: res.message,
            });
            if (res.record_id > 0) {
              setTimeout(() => {
                window.location = "/promotions/" + res.record_id;
              }, 1000);
            }
            else {
              setTimeout(() => {
                window.location = "/promotions";
              }, 1000);
            }
          } else {
            this.setState({
              type: "error",
              loadMessage: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState: editorState,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.pageRoot}>
        <Typography variant="h1">Promotion</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="standard-basic"
              variant="outlined"
              label="Title"
              value={this.state.title}
              name="title"
              fullWidth
              placeholder="Title"
              onChange={(e) => {
                this.setState({
                  title: e.target.value,
                });
              }}
              autoFocus={true}

              error={this.state.titleError === "" ? false : true}
              helperText={this.state.titleError}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="standard-basic"
              variant="outlined"
              label="Location"
              value={this.state.location}
              name="location"
              fullWidth
              placeholder="Location"
              onChange={(e) => {
                this.setState({
                  location: e.target.value,
                });
              }}

              error={this.state.locationError === "" ? false : true}
              helperText={this.state.locationError}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="standard-basic"
              variant="outlined"
              label="Promotion Time out"
              value={this.state.expiryDate}
              type="date"
              name="promotionTimeOut"
              fullWidth
              placeholder="Promotion Time out"
              onChange={(e) => {
                this.setState({
                  expiryDate: e.target.value,
                });
              }}

              error={this.state.expiryDateError === "" ? false : true}
              helperText={this.state.expiryDateError}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className={classes.userMeta}>
              <div className={classes.userLogo}>
                <img src="../../logo.png" alt="Logo" />
              </div>
              <List className={classes.list}>
                <ListItem>
                  <ListItemText className={classes.listItem} primary="Name" secondary={localStorage.getItem("q_name")} />
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.listItem} primary="Contact" secondary={localStorage.getItem("q_phone")} />
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.listItem} primary="Email" secondary={localStorage.getItem("q_email")} />
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.listItem} primary="Contact Person Name" secondary={localStorage.getItem("q_contact_person")} />
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.listItem} primary="Contact Person Mobile" secondary={localStorage.getItem("q_contact_person_mobile")} />
                </ListItem>
              </List>
            </div>
          </Grid>
        </Grid>
        {this.state.id > 0 &&
          <Grid item xs={12} className={classes.gridTop}>
            <HeaderImage
              msgLoader={this.msgLoader}
              id={this.state.id}
              headerImage={this.state.headerImage}
              prefix={this.state.slug + "/header-image/"}
              urlPrefix={this.state.prefix}
              config={config}
              ref={this.refHeaderImage}
              updateApi={"promotion-header"}
              bucket={config.promotion_bucket}

              bucket_name={config.promotion_bucket_name}
            />
          </Grid>
        }
        {this.state.id > 0 &&
          <SubImagesUpload
            id={this.state.id}
            setBoxMessage={this.setBoxMessage}
            inProcess={this.inProcess}
            config={config}
            msgLoader={this.msgLoader}
            prefix={this.state.slug + "/gallery/"}
            ref={this.refSubImagesUpload}
            updateApi={"promotion-images"}
            bucket={config.promotion_bucket}

            bucket_name={config.promotion_bucket_name}
          />
        }
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="standard-basic"
              variant="outlined"
              label="Youtube Video Link"
              value={this.state.video}
              name="video"
              fullWidth
              placeholder="Youtube Video"
              onChange={(e) => {
                this.setState({
                  video: e.target.value,
                });
              }}

              error={this.state.videoError === "" ? false : true}
              helperText={this.state.videoError}
            />
          </Grid>
        </Grid>
        {/* <div className={classes.sectionHeader}>
          <Typography variant="h3">Upload Video</Typography>
          <Button variant="contained" color="primary" component="label" disableElevation className={classes.uploadBtn}>
            <Upload size={16} />
            <i>Upload Video</i>
            <input type="text" value={this.state.video}/>
          </Button>
        </div> */}
        
        <Divider className={classes.divider} />
          {this.state.video &&
          <div className={classes.playerWrapper}>
            <ReactPlayer
              className={classes.reactPlayer}
              url={this.state.video}
              width='100%'
              height='100%'
            />
          </div>
          }
        <Editor
          className={classes.blogEditor}
          editorState={this.state.editorState}
          wrapperClassName={classes.blogContentWrapper}
          editorClassName={classes.blogContentEditor}
          onEditorStateChange={this.onEditorStateChange}
        />
        <div className={classes.btnGroup}>
          {this.state.id > 0 &&
          <Button
              variant="contained"
              className={classes.btnPreview}
              size="large"
              disableElevation
              onClick={this.handlePreview}
              startIcon={<Monitor size={18} />}
            >
            Preview
          </Button>
          }
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            startIcon={<ArrowLeft size={18} />}
            className={classes.cancelBtn}
            onClick={() => {
              window.location = "/promotions";
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            startIcon={<Save size={18} />}
            className={classes.saveBtn}
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </div>
        <Message type={this.state.type} msg={this.state.loadMessage} />
        <LogBox ref={this.refLogBox} />
      </div>
    );
  }
}

export default withStyles(useStyles)(AddPromotionClass);
