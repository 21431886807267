import { Folder, Grid, HelpCircle, Home, Image, Layout, Package, Users } from 'react-feather';
import { Link, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppBar from "@material-ui/core/AppBar";
import AuthService from "../auth/AuthService";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from 'prop-types';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    borderBottom: '#E7EAF3 1px solid',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    padding: theme.spacing(0, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingLeft: `calc(${drawerWidth}px + 30px)`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5),
    }
  },
  avatarContainer: {
    display: 'flex',
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  avatarLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  userAvatar: {
    marginRight: 8,
    '@media (max-width:576px)': {
      height: 30,
      width: 30,
    },
  },
  userName: {
    position: 'relative',
    top: 2,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  expandMoreIcon: {
    fontSize: 16,
    position: 'relative',
    top: 3,
  },
  logoImg: {
    height: 28,
    display: 'block',
    '@media (min-width:576px)': {
      minHeight: 45,
    }
  },
  customWidth: {
    
    '& div': {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      right: '24px!important',
      top: '50px!important',
      '@media (min-width:0px) and (orientation: landscape)': {
        top: '55px!important',
      },
      '@media (min-width:576px)': {
        top: '65px!important',
      },
    },
  },
  listItem: {
    textTransform: 'capitalize',
    borderRadius: 6,
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(1),
    '& svg': {
      color: theme.palette.primary.contrastText,  
    },
    '&:hover': {
      backgroundColor: '#572C00',
    },
    '&.selected': {
      backgroundColor: '#572C00',
    }
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #E7EAF3',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  let location = useLocation();

  // Role => 1-> Global Admin, 2-> Admin, 3 -> Seller, 4-> Blogger

  const [navLinks, setNavLinks] = React.useState([]);
  
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const Auth = new AuthService();

  const handleLogout = () => {
    Auth.logout();
    setAnchorEl(null);
  };

  useEffect(()=> {
    if(Auth.loggedIn())
    {
      let adminType = Auth.getAdminType();
      let roles = Auth.getUserRoles();
      if(adminType == 0)
      {
        let NavArr = ['dashboard', 'blogs', 'categories', 'users', 'admin blogs', 'blog positions', 'roles', 'car sales', 'promotions'];
        setNavLinks(NavArr);
      }
      else
      {
        let NavArr = ['dashboard'];// 'blogs', 'categories', 'users', 'admin blogs', 'blog positions', 'roles', 'car sales', 'promotions'];
        
        if(roles.super_admin === 1)
        {
          NavArr.push('blogs');
          NavArr.push('categories');
          NavArr.push('admin blogs');
          NavArr.push('blog positions');
          NavArr.push('car sales');
          NavArr.push('promotions');
        }
        else
        {
          if(roles.blogs === 1)
          {
            NavArr.push('blogs');
          }
          if(roles.categories === 1)
          {
            NavArr.push('categories');
          }
          
          NavArr.push('car sales');
          // if(roles.users === 1)
          // {
          //   NavArr.push('car sales');
          // }
          if(roles.promotions === 1)
          {
            NavArr.push('promotions');
          }
        }
        
        setNavLinks(NavArr);
      }
    }
  }, [])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }
  
  const drawer = (
    <List>
      {navLinks.map((text, index) => (
        <ListItem button key={text} alignItems="center" component={Link} to={`/${slugify(text)}`} className={`${location.pathname.includes(`/${slugify(text)}`) ? 'selected' : ''} ${classes.listItem}`}>
          <ListItemIcon>{index === 0 ? <Home size={20} /> : index === 1 ? <Layout size={20} /> : index === 2 ? <Grid size={20} /> : index === 3 ? <Users size={20} /> : index === 4 ? <Users size={20} /> : index === 5 ? <HelpCircle size={20} /> : index === 6 ? <Image size={20} /> : index === 7 ? <Package size={20} /> : index === 8 ? <Folder size={20} /> : ''}</ListItemIcon>
          <ListItemText primary={text} color="primary" />
        </ListItem>
      ))}
    </List>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/dashboard">
            <img className={classes.logoImg} src="../../qazai-wheels.svg" alt="Qizai Wheel" />
          </Link>
          <div className={classes.avatarContainer}>
            <div className={classes.avatarLabel} aria-controls="customized-menu" aria-haspopup="true" onClick={handleClick}>
              <Avatar className={classes.userAvatar} alt={Auth.getName()} src="../../user.jpg" />
              <Typography variant="body1" className={classes.userName}>{Auth.getName()}</Typography>
              <ExpandMoreIcon className={classes.expandMoreIcon} />
            </div>
          </div>
          <StyledMenu
            id="customized-menu"
            className={classes.customWidth}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/profile" onClick={handleClose}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {navLinks && drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {navLinks && drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>{props.children}</div>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;