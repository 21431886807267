import React, { Component } from "react";

import AuthService from "./auth/AuthService";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DNDApp from "./DNDApp";
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { Upload } from 'react-feather';
import cloneDeep from "lodash/cloneDeep";
import qs from "query-string";
import serverlesslink from "./../config";
import { withStyles } from "@material-ui/core/styles";

const useStyles = (theme) => ({
  sectionHeader: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
    backgroundColor: '#E7EAF3',
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '@media (max-width:576px)': {
      minWidth: '45px',
      height: '45px',
    },
    '& i': {
      fontStyle: 'normal',
      position: 'relative',
      top: 1,
      marginLeft: theme.spacing(1),
      '@media (max-width:576px)': {
        display: 'none',
      }
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
    }
  },
  headerImg: {
    maxWidth: "100%",
    display: "block",
    height: 300,
  },
});

class SubImagesUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rmImages: [],
      delImages: [],
      imageSorting: [],
      progress: [],
      completed: 0,
      files: [],
      uploaded_files: [],
      showMainProgress: false,
      config: null,
    };
    this.refTask = React.createRef();
    this.removeImg = this.removeImg.bind(this);
    this.fillImages = this.fillImages.bind(this);
    this.handleSubmitImage = this.handleSubmitImage.bind(this);
    this.setProgress = this.setProgress.bind(this);
    this.pullDataForDB = this.pullDataForDB.bind(this);
    this.Auth = new AuthService();
  }

  pullDataForDB(imageSorting) {
    console.log("imageSorting", imageSorting);
    this.setState({
      imageSorting: imageSorting,
    });
  }

  fillImages(images, next) {
    let that = this;
    that.setState(
      {
        progress: [],
        imageSorting: [],
      },
      () => {
        images.forEach((c) => {
          that.setState((state) => ({
            progress: state.progress.concat({
              id: c,
              // content: that.props.config.imageCDN + '/' + that.props.config.prefix + that.props.config.bucket + c + '?w=301',
              content: this.props.bucket + that.props.prefix + c,
              prog: state.rmImages.indexOf(c) > -1 ? 0 : 100,
            }),
            imageSorting: state.imageSorting.concat(c),
          }));
        });
        next();
      }
    );
  }

  removeImg(name, e) {
    // console.log('name', name)
    // console.log('name', this.props.config.bucketName + '/' + this.props.config.old_prefix + this.props.config.bucket + name)
    let progress = cloneDeep(this.state.progress);
    progress.filter((obj) => {
      if (obj.id === name) {
        obj.prog = 0;
        this.state.rmImages.concat(name);
      }
    });
    this.setState(
      (state) => ({
        progress: progress,
        rmImages: state.rmImages.concat(name),
      }),
      () => {
        this.props.inProcess(1);
        let list = this.state.imageSorting;
        // let s3 = this.get_wasabi_s3();
        let that = this;

        that.props.msgLoader(`Removing image ${name}`);
        this.Auth.fetch("/api/blogs/delete-images", {
          method: "POST",
          body: JSON.stringify({
            bucket: this.props.bucket,
            prefix: this.props.prefix,
            name: name,
          }),
        }).then((data) => {
          if (data.ack == true) {
            for (var i = list.length; i--; ) {
              if (list[i] === name) list.splice(i, 1);
            }

            that.fillImages(list, () => {
              that.setState(
                (state) => ({
                  delImages: state.delImages.concat(name),
                }),
                () => {
                  console.log("delImages", that.state.delImages);
                  that.refTask.current.handleFilesList(that.state.progress);
                  that.imagesDbUpdate(
                    "Image Removed And Database Updated.",
                    3000
                  );
                }
              );
            });
          }
        });
        // s3.copyObject({
        //   Bucket: that.props.config.bucketName,
        //   CopySource: that.props.config.bucketName + '/' + that.props.config.old_prefix + that.props.config.bucket + name,
        //   Key: that.props.config.deleted + that.props.config.bucket + name
        // }, function (err, data) {

        //   for (var i = list.length; i--;) {
        //     if (list[i] === name) list.splice(i, 1);
        //   }
        //   s3.deleteObject({
        //     Bucket: that.props.config.bucketName,
        //     Key: that.props.config.processed + that.props.config.bucket + name
        //   }, function (err, data) {
        //     that.fillImages(list, () => {
        //       that.setState(
        //         (state) => ({
        //           delImages: state.delImages.concat(name),
        //         }), () => {
        //           console.log('that.state', that.state)
        //           that.refTask.current.handleFilesList(
        //             that.state.progress
        //           )
        //           that.imagesDbUpdate("Image Removed And Database Updated.", 3000)
        //         })
        //     })
        //   })
        // });
      }
    );
  }

  handleFilesList(images, del_images) {
    // console.log('images', images)
    console.log("coentent: ", serverlesslink);
    images.forEach((c) => {
      if (c) {
        this.setState((state) => ({
          imageSorting: state.imageSorting.concat(c),
          progress: state.progress.concat({
            id: c,
            content: this.props.bucket + this.props.prefix + c,
            prog: 100,
          }),
        }));
      }
    });
    if (this.state.progress.length)
      this.refTask.current.handleFilesList(this.state.progress);

    if (del_images != null) {
      del_images.forEach((c) => {
        this.setState((state) => ({
          delImages: state.delImages.concat(c),
        }));
      });
    }
  }

  resetForm() {
    this.setState({
      rmImages: [],
      delImages: [],
      imageSorting: [],
      progress: [],
      completed: 0,
      files: [],
      uploaded_files: [],
      showMainProgress: false,
    });
  }

  imagesDbUpdate(message) {
    let that = this;

    let obj = {
      id: that.props.id,
      images: JSON.stringify(that.state.imageSorting),
      del_images: JSON.stringify(that.state.delImages),
    };

    this.Auth.fetch("/api/blogs/"+this.props.updateApi, {
      method: "PUT",
      body: JSON.stringify(obj),
    })
      .then((data) => {
        this.props.inProcess(0);
        that.props.msgLoader(data.message, 2000);
      })
      .catch((err) => {
        this.props.inProcess(0);
        console.log("error", err);
      });
  }

  setProgress(i, totalFiles, skipSaveDb = false) {
    if (!skipSaveDb) {
      if (i == totalFiles) {
        this.props.msgLoader("All Images Uploaded...", 4000);
        this.imagesDbUpdate("Files Saved To Database...");
      }
    }
    this.setState({ completed: (i / totalFiles + 1) * 100 });
  }

  progressEach(name, preview) {
    this.state.imageSorting.unshift(name);
    this.state.progress.unshift({ id: name, content: preview, prog: 0 });

    this.refTask.current.handleFilesList(this.state.progress);
  }

  handleSubmitImage(e) {
    console.log("inside handle subbmit");
    this.props.inProcess(1);

    let acceptedFiles = e.target.files;
    this.setState({ uploaded_files: acceptedFiles });
    let that = this;
    let totalFiles = acceptedFiles.length;
    if (totalFiles > 0) {
      this.props.msgLoader("Uploading Images...");
    }
    var i = 0;
    for (let file of acceptedFiles) {
      let fileName = file.name.replace(".JPG", ".jpg");

      // removed the image type check
      // if (!fileName.match(/.(jpg)$/i)) {
      //   i++;
      //   that.setProgress(i, totalFiles, true);
      //   that.props.msgLoader(`Only .jpg files are allowed`, 2000);
      //   that.props.inProcess(0);
      // } else 
      if (this.state.imageSorting.includes(fileName)) {
        i++;
        that.setProgress(i, totalFiles, true);
        that.props.setBoxMessage({
          type: "error",
          msg: `${fileName} already uploaded.`,
        });
        that.props.msgLoader(`${fileName} already uploaded.`, 2000);
        that.props.inProcess(0);
      } else {
        this.state.showMainProgress = true;
        // let params = {
        //   Bucket: that.props.config.bucketName,
        //   Key: that.props.config.prefix + that.props.config.bucket + fileName,
        //   Body: file,
        //   ContentType: file.type,
        //   ACL: 'public-read'
        // };

        let params = {
          bucket: this.props.bucket_name,
          ContentType: file.type,
          Key: that.props.prefix + fileName,
        };

        that.progressEach(fileName, URL.createObjectURL(file));

        /* WORKING */
        const name = params.Key.split("/").pop();
        fetch(
          `${serverlesslink.domain}/generate-put-url?${qs.stringify(params)}`,
          {
            headers: {
              "Content-Type": params.ContentType,
            },
          }
        )
          .then((response) => response.json())
          // eslint-disable-next-line no-loop-func
          .then((res) => {
            fetch(res.putURL, {
              method: "put",
              body: file,
              headers: {
                "Content-Type": params.ContentType,
              },
            })
              .then((response) => response)
              .then((data) => {
                if (data.status === 200) {
                  let progress = cloneDeep(that.state.progress);
                  progress.filter((obj) => {
                    if (obj.id === name) obj.prog = 100;
                  });
                  that.setState({ progress: progress }, () => {
                    this.refTask.current.handleFilesList(this.state.progress);
                  });
                  that.props.setBoxMessage({
                    type: "success",
                    msg: `${name} Uploaded.`,
                  });

                  if (++i >= totalFiles) {
                    that.setProgress(i, totalFiles);
                  } else {
                  }
                } else {
                  let imageSorting = this.state.imageSorting;
                  let that = this;
                  for (var j = imageSorting.length; j--; ) {
                    if (imageSorting[j] === name) imageSorting.splice(j, 1);
                  }
                  that.setState({ imageSorting: imageSorting });
                  that.props.setBoxMessage({
                    type: "error",
                    msg: `Image not uploaded: ${name}`,
                  });
                  that.props.msgLoader(`Splice image: ${name}`);
                  if (++i >= totalFiles) {
                    that.setProgress(i, totalFiles);
                  }
                }
              });
          })
          .catch((error) => {
            console.error("Error:", error);
          });

        // wasabi_s3.upload(params, (err, data) => {
        //   let name = params.Key.split('/').pop()
        //   if (!err) {
        //     let progress = cloneDeep(that.state.progress);
        //     progress.filter(obj => {
        //       if (obj.id == name) obj.prog = 100
        //     })
        //     that.setState({ progress: progress }, () => {
        //       this.refTask.current.handleFilesList(
        //         this.state.progress
        //       )
        //     });
        //     that.props.setBoxMessage({ type: 'success', msg: `${name} Uploaded.` })

        //     if (++i >= totalFiles) {
        //       that.setProgress(i, totalFiles); // @TODO
        //     } else {

        //     }
        //   } else {
        //     let imageSorting = this.state.imageSorting
        //     let that = this
        //     for (var j = imageSorting.length; j--;) {
        //       if (imageSorting[j] === name) imageSorting.splice(j, 1);
        //     }
        //     that.setState({ imageSorting: imageSorting });
        //     that.props.setBoxMessage({ type: 'error', msg: `Image not uploaded: ${name}` })
        //     that.props.msgLoader(`Splice image: ${name}`)
        //     if (++i >= totalFiles) {
        //       that.setProgress(i, totalFiles);
        //     }
        //   }
        // })
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { bucket } = this.props;
    console.log("bucket", bucket);
    if (bucket === false) {
      return `Bucket not created yet!`;
    } else {
      return (
        <>
          <div className={classes.sectionHeader}>
            <Typography variant="h3">Upload Gallery Images</Typography>
            <Button variant="contained" color="primary" component="label" disableElevation className={classes.uploadBtn}>
              <Upload size={16} />
              <i>Upload Gallery Image</i>
              <input type="file" multiple hidden onChange={this.handleSubmitImage} accept="image/*" />
            </Button>
            {/* <div>
              <label for="galleryImages" className={classes.fileUpload}>
                <CloudUploadIcon className={classes.uploadIcon} />
                Upload Gallery Images
              </label>
              <input
                type="file"
                id="galleryImages"
                multiple
                onChange={this.handleSubmitImage}
                accept="image/*"
                className={classes.uploadBtn}
              />
            </div> */}
          </div>
          <Divider className={classes.divider} />
          <DNDApp
            className={classes.dndApp}
            removeImg={this.removeImg}
            pullDataForDB={this.pullDataForDB}
            ref={this.refTask}
          />
          <LinearProgress
            style={{ display: this.state.showMainProgress ? "block" : "none" }}
            color="secondary"
            variant="determinate"
            value={this.state.completed || 0}
          />
        </>
      );
    }
  }
}

export default withStyles(useStyles)(SubImagesUpload);
