// @flow

import React, { Component } from "react";
import { borderRadius, grid } from "./constants";

import Message from "./../common/Message"
import { colors } from "@atlaskit/theme";
import styled from "@emotion/styled";

const config = require("./../../config");
// import LinearProgress from '@material-ui/core/LinearProgress';

const primaryButton = 0;

const getBackgroundColor = ({ isSelected, isGhosting }) => {
  if (isGhosting) {
    return colors.N10;
  }

  if (isSelected) {
    return colors.B100;
  }

  return colors.N10;
};

const getColor = ({ isSelected, isGhosting }) => {
  if (isGhosting) {
    return "darkgrey";
  }
  if (isSelected) {
    return colors.B200;
  }
  return colors.N900;
};

const Container = styled.div`
  min-width: 200px;
  background-color: ${(props) => getBackgroundColor(props)};
  color: ${(props) => getColor(props)};
  padding: ${grid}px;
  margin-bottom: ${grid}px;
  border-radius: ${borderRadius}px;
  font-size: 18px;
  border: 1px solid ${colors.N90};
  ${(props) =>
    props.isDragging ? `box-shadow: 2px 2px 1px ${colors.N90};` : ""} ${(
    props
  ) => (props.isGhosting ? "opacity: 0.8;" : "")}

  /* needed for SelectionCount */
  position: relative;

  /* avoid default outline which looks lame with the position: absolute; */
  &:focus {
    outline: none;
    border-color: ${colors.N200};
  }
`;
/* stylelint-disable block-no-empty */
const Content = styled.div``;
/* stylelint-enable */
const size = 30;

const SelectionCount = styled.div`
  right: -18px;
  top: -18px;
  color: ${colors.N0};
  background: ${colors.N200};
  border-radius: 50%;
  height: ${size}px;
  width: ${size}px;
  line-height: ${size}px;
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
  z-index: 9999;
`;

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9,
};

export default class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMessage : "",
      type: ""
    };
    this.onClick = this.onClick.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.wasToggleInSelectionGroupKeyUsed = this.wasToggleInSelectionGroupKeyUsed.bind(
      this
    );
    this.wasMultiSelectKeyUsed = this.wasMultiSelectKeyUsed.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.downloadImage = this.downloadImage.bind(this);
    this.imgCopyHandler = this.imgCopyHandler.bind(this);
  }

  downloadImage(image) {
    // console.log(image)
    window.open(image.split("?")[0]);
    // window.location = image
  }

  imgClickHandler(task) {
    task.prog = 0;
    this.props.removeImg.call(this, task.id);
  }
  imgCopyHandler(task)
  {
    // console.log('clicked')
    // this.setState({
    //   loadMessage : "Image link copied",
    //   type: "success"
    // })
    // navigator.clipboard.writeText(task.content);

    var input = document.createElement('textarea');
    input.innerHTML = task.content;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    this.setState({
        loadMessage : "Image link copied",
        type: "success"
      });
    return result;
    
  }

  onKeyDown(event, provided, snapshot) {
    if (provided.dragHandleProps) {
      provided.dragHandleProps.onKeyDown(event);
    }

    if (event.defaultPrevented) {
      return;
    }

    if (snapshot.isDragging) {
      return;
    }

    if (event.keyCode !== keyCodes.enter) {
      return;
    }

    // we are using the event for selection
    event.preventDefault();

    this.performAction(event);
  }
  // Using onClick as it will be correctly
  // preventing if there was a drag
  onClick(event) {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }

    // marking the event as used
    event.preventDefault();

    this.performAction(event);
  }

  onTouchEnd(event) {
    if (event.defaultPrevented) {
      return;
    }

    // marking the event as used
    // we would also need to add some extra logic to prevent the click
    // if this element was an anchor
    event.preventDefault();
    this.props.toggleSelectionInGroup(this.props.task.id);
  }

  // Determines if the platform specific toggle selection in group key was used
  wasToggleInSelectionGroupKeyUsed(event) {
    const isUsingMac = navigator.platform.toUpperCase().indexOf("MAC") > -1;
    return isUsingMac ? event.metaKey : event.ctrlKey;
  }

  // Determines if the multiSelect key was used
  wasMultiSelectKeyUsed(event) {
    return event.shiftKey;
  }

  performAction(event) {
    const {
      task,
      toggleSelection,
      toggleSelectionInGroup,
      multiSelectTo,
    } = this.props;

    if (this.wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(task.id);
      return;
    }

    if (this.wasMultiSelectKeyUsed(event)) {
      multiSelectTo(task.id);
      return;
    }

    toggleSelection(task.id);
  }

  render() {
    const provided = this.props.provided;
    const snapshot = this.props.snapshot;
    const task = this.props.task;
    const isSelected = this.props.isSelected;
    const selectionCount = this.props.selectionCount;
    const isGhosting = this.props.isGhosting;

    const shouldShowSelection = snapshot.isDragging && selectionCount > 1;

    return (
      <div className="image-space">
        <div className="image-space-inner">
          <Container
            className="image-container"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={this.onClick}
            onTouchEnd={this.onTouchEnd}
            onKeyDown={(event) => this.onKeyDown(event, provided, snapshot)}
            isDragging={snapshot.isDragging}
            isSelected={isSelected}
            isGhosting={isGhosting}
          >
            <Content>
              <img
                style={{
                  display: task.prog == 100 ? "block" : "none",
                }}
                className="close-icon"
                src={config.domain + "/images/close-icon.png"}
                onClick={() => this.imgClickHandler(task)}
              />
              <img
                style={{ width: "100%" }}
                className="cover spinner loading"
                src={task.content}
              />
              {task.prog === 0 ? (
                <div className="loading-text">Processing ...</div>
              ) : (
                ""
              )}

              <img
                style={{
                  display: task.prog == 100 ? "block" : "none",
                }}
                className="download-img"
                src={config.domain + "/images/download-icon.png"}
                onClick={() => this.downloadImage(task.content)}
              />
              <img
                style={{
                  display: task.prog == 100 ? "block" : "none",
                }}
                className="copy-icon"
                src={config.domain + "/images/copy-icon.png"}
                onClick={() => this.imgCopyHandler(task)}
              />
              {/* <a target="_blank" className="download-img" href={task.content} download="test.jpg">
                                <img
                                    src={SRCBucketURL + '/asset/images/get_app_black_24x24.png'}
                                />
                            </a> */}
            </Content>

            {shouldShowSelection ? (
              <SelectionCount>{selectionCount}</SelectionCount>
            ) : null}
          </Container>
          <Message type={this.state.type} msg={this.state.loadMessage} />
        </div>
      </div>
    );
  }
}
