const dev = {
  domain: "http://localhost:3000",
  blog_bucket: "https://qizai-blog.s3.us-east-2.amazonaws.com/",
  promotion_bucket: "https://qizai-promotions.s3.us-east-2.amazonaws.com/",
  car_sales_bucket: "https://qizai-car-sales.s3.us-east-2.amazonaws.com/",
  blog_bucket_name: "qizai-blog",
  car_sales_bucket_name: "qizai-car-sales",
  promotion_bucket_name: "qizai-promotions",
  
  SRCBucketURL: "https://d20n9mn4bjrctk.cloudfront.net",
};

const uat = {
  domain: "https://7360i66l3j.execute-api.us-east-2.amazonaws.com/latest",
  blog_bucket: "https://qizai-blog.s3.us-east-2.amazonaws.com/",
  car_sales_bucket: "https://qizai-car-sales.s3.us-east-2.amazonaws.com/",
  promotion_bucket: "https://qizai-promotions.s3.us-east-2.amazonaws.com/",
  blog_bucket_name: "qizai-blog",
  car_sales_bucket_name: "qizai-car-sales",
  promotion_bucket_name: "qizai-promotions",
  SRCBucketURL: "https://d20n9mn4bjrctk.cloudfront.net",
};

const prod = {
  domain: "https://gwhgfws8oi.execute-api.us-east-2.amazonaws.com/latest",
  blog_bucket: "https://qizai-blog.s3.us-east-2.amazonaws.com/",
  car_sales_bucket: "https://qizai-car-sales.s3.us-east-2.amazonaws.com/",
  promotion_bucket: "https://qizai-promotions.s3.us-east-2.amazonaws.com/",
  blog_bucket_name: "qizai-blog",
  car_sales_bucket_name: "qizai-car-sales",
  promotion_bucket_name: "qizai-promotions",
  SRCBucketURL: "https://d20n9mn4bjrctk.cloudfront.net",
};

const live = {
  domain: "https://qizaiwheels.com",
  blog_bucket: "https://qizai-blog.s3.us-east-2.amazonaws.com/",
  car_sales_bucket: "https://qizai-car-sales.s3.us-east-2.amazonaws.com/",
  promotion_bucket: "https://qizai-promotions.s3.us-east-2.amazonaws.com/",
  blog_bucket_name: "qizai-blog",
  car_sales_bucket_name: "qizai-car-sales",
  promotion_bucket_name: "qizai-promotions",
  SRCBucketURL: "https://d20n9mn4bjrctk.cloudfront.net",
};

module.exports = live;
