const taskMap = (tasks) => tasks.reduce(
  (previous, current) => {
    previous[current.id] = current;
    return previous;
  },
  {},
);

const cols = 4

const chunk = (array, size) => {
  const chunked_arr = [];
  for (let i = 0; i < array.length; i++) {
    const last = chunked_arr[chunked_arr.length - 1];
    if (!last || last.length === size) {
      chunked_arr.push([array[i]]);
    } else {
      last.push(array[i]);
    }
  }
  return chunked_arr;
}

export const chunkFunc = chunk

export const initialAutoData = (images) => {
  let imgArr = chunk(images, cols)
  let fullImage = images
  // console.log('imgArr', imgArr)
  let columns = []
  let columnOrder = []
  if (imgArr.length) {
    for (let i = 1; i < imgArr.length + 1; i++) {
      columns[i] = { id: i, taskIds: imgArr[i - 1].map((task) => task.id) }
      columnOrder.push(i)
    }
  } else {
    columns[1] = { id: 1, taskIds: [] }
    columnOrder.push(1)
  }


  return {
    columnOrder: columnOrder,
    columns: columns,
    tasks: taskMap(fullImage),
  }
};

