import "./App.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import AddBlogClass from "./components/AddBlogClass";
import AddCarSaleClass from "./components/AddCarSaleClass";
import AddCategory from "./components/AddCategory";
import AddPromotionClass from "./components/AddPromotionClass";
import AddRole from "./components/AddRole";
import BlogPositions from "./components/BlogsPosition";
import Blogs from "./components/Blogs";
import BlogsAdmin from "./components/BlogsAdmin";
import CarSalesList from "./components/CarSalesList";
import Categories from "./components/Categories";
import Dashboard from "./components/Dashboard";
import Layout from "./components/Layout";
import PromotionsList from "./components/PromotionsList";
import ProtectedRoute from "./ProtectedRoute";
import ReactDOM from "react-dom";
import Register from "./components/Register";
import RolesList from "./components/RolesList";
import SignIn from "./components/auth/SignIn";
import UserDetails from "./components/UserDetails";
import UserList from "./components/UserList"
import UserProfile from "./components/UserProfile";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#B14F1E',
    },
    secondary: {
      main: '#343A40',
    },
    text: {
      secondary: '#343A40',
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Rubik',
    h1: {
      fontFamily: 'Playfair Display',
      fontWeight: 500,
      fontSize: 20,
      '@media (min-width:991px)': {
        fontSize: 26,
      }
    },
    h2: {
      fontFamily: 'Playfair Display',
      fontWeight: 500,
      fontSize: 20,
      '@media (min-width:991px)': {
        fontSize: 26,
      }
    },
    h3: {
      fontFamily: 'Playfair Display',
      fontWeight: 500,
      fontSize: 20,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
    },
    body2: {
      fontWeight: 300,
      fontSize: 14,
    },
    caption: {
      fontWeight: 400,
      fontSize: 14,
    },
    button: {
      fontFamily: 'Rubik',
      fontSize: 14,
    }
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "white",
        color: '#343A40'
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 6,
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      }
    },
    MuiButton: {
      root: {
        borderRadius: 6,
        fontSize: '14px',
        height: 50,
        paddingLeft: '20px',
        paddingRight: '20px',
        minWidth: 'auto',
      }
    },
    MuiTable: {
      root: {
        '& tbody': {
          '& tr:nth-child(even)': {
            backgroundColor: 'rgba(177, 79, 30, 0.04)',
          },
          '& tr:last-child td': {
            border: 'none',
          }
        }
      }
    },
    MuiTableCell: {
      color: '#B14F1E',
      head: {
        fontFamily: 'Playfair Display',
        fontWeight: 500,
        fontSize: 16,
      },
      body: {
        fontSize: 14,
        fontWeight: 400,
        paddingTop: 8,
        paddingBottom: 8,
      }
    },
    MuiPaginationItem: {
      root: {
        fontWeight: 400,
        backgroundColor: '#fff',
        border: 'rgba(177, 79, 30, 0.04) 1px solid',
        color: '#343A40',
        lineHeight: '1.5',
        '&$selected': {
          backgroundColor: '#B14F1E!important',
          color: '#fff!important',
          border: '1px solid #B14F1E!important',
        }
      },
    }
  },
  mixins: {
    toolbar: {
      minHeight: 50,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 55
      },
      '@media (min-width:600px)': {
        minHeight: 65
      }
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/register" component={Register} />
          <Layout>
            <Switch>
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />
              <ProtectedRoute exact path="/blogs" component={Blogs} />
              <ProtectedRoute exact path="/blogs/:id" component={AddBlogClass} />
              <ProtectedRoute exact path="/profile" component={UserProfile} />
              <ProtectedRoute exact path="/categories" component={Categories} />
              <ProtectedRoute exact path="/users" component={UserList} />
              <ProtectedRoute exact path="/users/:id" component={UserDetails} />
              <ProtectedRoute exact path="/admin-blogs" component={BlogsAdmin} />
              <ProtectedRoute exact path="/blog-positions" component={BlogPositions} />
              <ProtectedRoute exact path="/roles" component={RolesList} />
              <ProtectedRoute exact path="/roles/:id" component={AddRole} />
              <ProtectedRoute exact path="/promotions" component={PromotionsList} />
              <ProtectedRoute exact path="/promotions/:id" component={AddPromotionClass} />
              <ProtectedRoute exact path="/car-sales" component={CarSalesList} />
              <ProtectedRoute exact path="/car-sales/:id" component={AddCarSaleClass} />
              <ProtectedRoute
                exact
                path="/categories/:id"
                component={AddCategory}
              />
            </Switch>
          </Layout>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
