import { Eye, EyeOff, Upload } from 'react-feather';
import React, {useEffect, useRef, useState} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Link from "@material-ui/core/Link";
import Message from "./common/Message";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import clsx from 'clsx';
import config from "./../config";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    // height: 'calc(100vh - 60px)',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "520px",
    margin: theme.spacing(0, 'auto'),
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  logoImg: {
    height: 45,
    display: "block",
    margin: theme.spacing(0, 0, 4, 0),
  },
  auth: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: theme.spacing(5),
    border: "1px solid #E7EAF3",
    width: '100%',
    boxSizing: 'border-box',
    '@media(max-width: 576px)': {
      padding: theme.spacing(3),
    }
  },
  uploadBtnContainer: {
    marginTop: 16,
    marginBottom: 8,
    position: 'relative',
  },
  file: {
    display: 'none',
  },
  uploadBtn: {
    width: '100%',
    height: 53.63,
    justifyContent: 'start',
    textTransform: 'initial',
    fontSize: 14,
    fontWeight: 'normal',
    backgroundColor: 'white',
    border: "#E7EAF3 1px solid",
    paddingLeft: '14px',
    paddingRight: '45px',
    '&:hover': {
      backgroundColor: 'white',
    },
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  },
  uploadBtnIcon: {
    position: 'absolute',
    top: '18px',
    right: '16px',
  },
  passIcon: {
    position: 'absolute',
    top: '34px',
    right: '16px',
    cursor: 'pointer',
  },
  authTitle: {
    margin: theme.spacing(0, 0, 2.5, 0),
  },
  subTitle: {
    margin: theme.spacing(0, 0, 2.5, 0),
  },
  colFirst: {
    paddingRight: theme.spacing(1.2),
    '@media(max-width: 576px)': {
      paddingRight: theme.spacing(0),
    }
  },
  colSecond: {
    paddingLeft: theme.spacing(1.2),
    '@media(max-width: 576px)': {
      paddingLeft: theme.spacing(0),
    }
  },
  passField: {
    position: 'relative'
  },
  gridText: {
    margin: theme.spacing(1.5, 0, 1, 0),
  },
  submit: {
    margin: theme.spacing(1.5, 0, 0, 0),
  },
  footer: {
    margin: theme.spacing(4, 0, 0, 0),
  }
}));

const initialFormData = Object.freeze({
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  subscribe: "",
  companyName: "",
  telNo: "",
  contactPersonName: "",
  contactPersonMobile: ""
});

export default function SignUp(props) {
  const classes = useStyles();

  const [error, setError] = React.useState(null);
  const [formData, updateFormData] = React.useState(initialFormData);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("success");

  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  const Auth = new AuthService();

  useEffect(() => {
    if (Auth.loggedIn()) props.history.replace("/dashboard");
  }, []);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const validateRegister = (formData) => {
    let flg = true;
    const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    if (formData.firstName === "") {
      setFirstNameError("Required");
      flg = false;
    } else {
      setFirstNameError("");
    }

    if (formData.lastName === "") {
      setLastNameError("Required");
      flg = false;
    } else {
      setLastNameError("");
    }

    if (formData.email === "") {
      setEmailError("Required");
      flg = false;
    } else if (!emailRegex.test(formData.email)) {
      setEmailError("Invalid email");
      flg = false;
    } else {
      setEmailError("");
    }

    if (formData.password === "") {
      setPasswordError("Required");
      flg = false;
    } else if (!passwordRegex.test(formData.password)) {
      //     // At least one digit [0-9]
      //     // At least one lowercase character [a-z]
      //     // At least one uppercase character [A-Z]
      //     // At least one special character [*.!@#$%^&(){}[]:;<>,.?/~_+-=|\]
      //     // At least 8 characters in length, but no more than 32.
      setPasswordError(
        "Invalid password must be minimum eight characters, at least one letter and one number"
      );
      flg = false;
    } else {
      setPasswordError("");
    }
    return flg;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("inside handle submit");
    console.log(formData);
    if (validateRegister(formData)) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      };
      fetch(`${config.domain}/api/auth/register`, requestOptions)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.ack) {
              setType("success");
              setLoadMessage(result.message);

              setTimeout(() => {
                props.history.push("/");
              }, 2000);
            } else {
              setType("error");
              setLoadMessage(result.message);
              setError(result.status);
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
          }
        );
    } else return false;
  };

  function Copyright() {
    return (
      <Typography className={classes.footer} variant="caption" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="#">
        Qizai Wheels
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  const [tradeLicense, setTradeLicense] = useState('');
  const [uploadPassport, setUploadPassport] = useState('');
  const [uploadLogo, setUploadLogo] = useState('');

  const handleTradeLicense = (e) => {
    const image = e.target.files[0];
    setTradeLicense(imageFile => (image));
  }

  const handleUploadPassport = (e) => {
    const image = e.target.files[0];
    setUploadPassport(imageFile => (image));
  }
  
  const handleUploadLogo = (e) => {
    const image = e.target.files[0];
    setUploadLogo(imageFile => (image));
  }

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handlePassChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <div className={classes.root}>
      <img className={classes.logoImg} src="../../qazai-wheels.svg" alt="Qizai Wheel" />
      <div className={classes.auth}>
        <Typography variant="h1" color="textSecondary" align="center" className={classes.authTitle} gutterBottom>Create Your Account</Typography>
        <Typography color="textSecondary" variant="caption" display="block" align="center" className={classes.subTitle}>Already have an account? <Link to="#">Sign in</Link></Typography>
        <form className={classes.form}>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.colFirst}>
              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="firstName"
                label="First Name"
                placeholder="First Name"
                name="firstName"
                autoComplete="firstName"
                autoFocus
                onChange={handleChange}
                error={firstNameError === "" ? false : true}
                helperText={firstNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}  className={classes.colSecond}>
              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
                autoComplete="lastName"
                onChange={handleChange}
                error={lastNameError === "" ? false : true}
                helperText={lastNameError}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Your Email"
                placeholder="info@qizaiwheel.com"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                error={emailError === "" ? false : true}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.passField}>
                <CssTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="password"
                  label="Password"
                  placeholder="8+ characters required, at least one letter and one number"
                  name="password"
                  autoComplete="password"
                  type={values.showPassword ? 'text' : 'password'}
                  // value={values.password}
                  onChange={handleChange}
                  error={passwordError === "" ? false : true}
                  helperText={passwordError}
                />
                <div className={classes.passIcon} onClick={handleClickShowPassword}>
                  {values.showPassword ? <Eye size={16} /> : <EyeOff size={16} />}
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.uploadBtnContainer}>
                  <input accept="image/*" className={classes.file} id="tradeLicense" type="file" onChange={handleTradeLicense} />
                  <label htmlFor="tradeLicense">
                    <Button variant="contained" component="span" className={classes.uploadBtn} disableElevation>
                      {tradeLicense.name ? tradeLicense.name : 'Uplaod Trade License'}
                    </Button>
                  </label>
                  <Upload className={classes.uploadBtnIcon} size={16} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.uploadBtnContainer}>
                <input accept="image/*" className={classes.file} id="uploadPassport" type="file" onChange={handleUploadPassport} />
                <label htmlFor="uploadPassport">
                  <Button variant="contained" component="span" className={classes.uploadBtn} disableElevation>
                    {uploadPassport.name ? uploadPassport.name : 'Uplaod passport and Visa Copy'}
                  </Button>
                </label>
                <Upload className={classes.uploadBtnIcon} size={16} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.colFirst}>
              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="companyName"
                label="Company Name"
                placeholder="Qizai Wheel"
                name="companyName"
                autoComplete="companyName"
                onChange={handleChange}
                // error={emailError === "" ? false : true}
                // helperText={emailError}
              />
            </Grid>
            <Grid item xs={12} sm={6}  className={classes.colSecond}>
              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="telNo"
                label="Tel No"
                placeholder="+92 111 111 1111"
                name="telNo"
                autoComplete="telNo"
                onChange={handleChange}
                // error={emailError === "" ? false : true}
                // helperText={emailError}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.colFirst}>
              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="contactPersonName"
                label="Contact Person Name"
                placeholder="Contact Person Name"
                name="contactPersonName"
                autoComplete="contactPersonName"
                onChange={handleChange}
                // error={emailError === "" ? false : true}
                // helperText={emailError}
              />
            </Grid>
            <Grid item xs={12} sm={6}  className={classes.colSecond}>
              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="contactPersonMobile"
                label="Contact Person Mobile"
                placeholder="+92 000 000 0000"
                name="contactPersonMobile"
                autoComplete="contactPersonMobile"
                onChange={handleChange}
                // error={emailError === "" ? false : true}
                // helperText={emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="location"
                label="Location / Address"
                placeholder="London"
                name="location"
                autoComplete="location"
                onChange={handleChange}
                // error={emailError === "" ? false : true}
                // helperText={emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.uploadBtnContainer}>
                <input accept="image/*" className={classes.file} id="uploadLogo" type="file" onChange={handleUploadLogo} />
                <label htmlFor="uploadLogo">
                  <Button variant="contained" component="span" className={classes.uploadBtn} disableElevation>
                    {uploadLogo.name ? uploadLogo.name : 'Upload Logo'}
                  </Button>
                </label>
                <Upload className={classes.uploadBtnIcon} size={16} />
              </div>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            Create an account
          </Button>
        </form>
      </div>
      <Message type={type} msg={loadMessage} />
      <Copyright />
    </div>
  );
}
