// import decode from 'jwt-decode';
// import jwt from 'jsonwebtoken';

import config from "./../../config";
import decode from "jwt-decode";

export default class AuthService {
  // Initializing important variables
  constructor(domain) {
    this.domain = domain || config.domain; // API server domain
    this.fetch = this.fetch.bind(this); // React binding stuff
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.loggedIn = this.loggedIn.bind(this);
  }

  login(email, password) {
    return this.fetch("/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: new URLSearchParams({
        email: email,
        password: password,
      }),
    })
      .then((res) => {
        if (res.ack) {
          this.setLocalStorage(res);
          return Promise.resolve(res);
        } else {
          return Promise.reject(res);
        }
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage
    // console.log('token', token)
    return !!token; // handwaiving here
  }

  getAdminType() {
    // Retrieves the admin type from localStorage
    return this.getProfile().role;
  }
  
  getUserRoles() {
    // Retrieves the admin type from localStorage
    return this.getProfile().user_roles;
  }

  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("q_name");
    localStorage.removeItem("q_phone");
    localStorage.removeItem("q_email");
    localStorage.removeItem("q_contact_person");
    localStorage.removeItem("q_contact_person_mobile");
    localStorage.setItem("drawer_open", 0);
    localStorage.setItem("drawer_open_right", 0);
    window.location.replace("/");
  }

  fetch(url, options) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (this.loggedIn()) {
      headers["x-api-key"] = this.getToken();
    }
    return fetch(`${this.domain}${url}`, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  post(url, body) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    };
    if (this.loggedIn()) {
      headers["x-api-key"] = this.getToken();
    }
    return fetch(`${this.domain}${url}`, {
      headers,
      method: "POST",
      body: new URLSearchParams(body),
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  _checkStatus(response) {
    // console.log('response', response)
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      if (response.status === 401 || response.status === 403) {
        console.log("Forbidden");
        localStorage.removeItem("id_token");
        localStorage.setItem("drawer_open", 0);
        localStorage.setItem("drawer_open_right", 0);
        window.location.replace("/");
        // return response;
      }
    }
  }

  setLocalStorage(res) {
    // Saves user token to localStorage
    localStorage.setItem("id_token", res.token);
    localStorage.setItem("q_name", res.name);
    localStorage.setItem("q_phone", res.phone);
    localStorage.setItem("q_email", res.email);
    localStorage.setItem("q_contact_person", res.contact_person ? res.contact_person : "");
    localStorage.setItem("q_contact_person_mobile", res.contact_person_mobile ? res.contact_person_mobile : "");
    localStorage.setItem("drawer_open", 0);
    localStorage.setItem("drawer_open_right", 0);
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("id_token");
  }

  getName() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("q_name");
  }
  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }
}
