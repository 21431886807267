// @flow
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { DragDropContext } from "react-beautiful-dnd"
import List from './dnd/list';
import { grid } from './dnd/constants';
import { initialAutoData } from "./dnd/data"
// import { reorderQuoteMap } from './dnd/reorder';
import { mutliDragAwareReorder, multiSelectTo as multiSelect } from './dnd/utils';
import Alert from '@material-ui/lab/Alert';

import { withStyles } from "@material-ui/core/styles";

const Root = styled.div`
  overflow: auto;
  min-height: 100vh;

  /* flexbox */
  display: flex;
  flex-direction: column;
`;



const getTasks = (entities, columnId) =>
    entities.columns[columnId].taskIds.map(
        (taskId) => entities.tasks[taskId],
    );

const useStyles = (theme) => ({
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: '#3f51b5',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
    spinner: {
        display: "flex",
        justifyContent: "center",
        marginTop: 25,
        marginBottom: 25,
    }
});

class DNDApp extends Component {
    /* eslint-disable react/sort-comp */
    constructor(props) {
        super(props);

        this.state = {
            // quoteMap: this.props.initial,
            selectedTaskIds: [],
            entities: null
        }
        this.onDragStart = this.onDragStart.bind(this);
        this.onWindowKeyDown = this.onWindowKeyDown.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onWindowClick = this.onWindowClick.bind(this);
        this.onWindowTouchEnd = this.onWindowTouchEnd.bind(this);
        this.toggleSelection = this.toggleSelection.bind(this);
        this.toggleSelectionInGroup = this.toggleSelectionInGroup.bind(this);
        this.multiSelectTo = this.multiSelectTo.bind(this);
        this.unselect = this.unselect.bind(this);
        this.unselectAll = this.unselectAll.bind(this);
        this.handleFilesList = this.handleFilesList.bind(this);
    }

    componentDidMount () {
        window.addEventListener('click', this.onWindowClick);
        window.addEventListener('keydown', this.onWindowKeyDown);
        window.addEventListener('touchend', this.onWindowTouchEnd);
    }

    componentWillUnmount () {
        window.removeEventListener('click', this.onWindowClick);
        window.removeEventListener('keydown', this.onWindowKeyDown);
        window.removeEventListener('touchend', this.onWindowTouchEnd);
    }

    onDragStart (start) {
        const id = start.draggableId;
        const selected = this.state.selectedTaskIds.find(
            (taskId) => taskId === id,
        );

        // if dragging an item that is not selected - unselect all items
        if (!selected) {
            this.unselectAll();
        }
        this.setState({
            draggingTaskId: start.draggableId,
        });
    };

    onDragEnd (result) {
        const destination = result.destination;
        const source = result.source;

        // nothing to do
        if (!destination || result.reason === 'CANCEL') {
            this.setState({
                draggingTaskId: null,
            });
            return;
        }

        const processed = mutliDragAwareReorder({
            entities: this.state.entities,
            selectedTaskIds: this.state.selectedTaskIds,
            source,
            destination,
        });

        // console.log('processed', processed)

        const reorderme = processed.entities.columnOrder
        let allImages = []
        let allSorted = []
        reorderme.forEach(element => {
            processed.entities.columns[element].taskIds.forEach(element => {
                allImages.push(processed.entities.tasks[element])
                allSorted.push(processed.entities.tasks[element].id)
            });
        });

        this.props.pullDataForDB(allSorted)

        this.setState({
            entities: initialAutoData(allImages),
            // ...processed,
            draggingTaskId: null,
        });
    };


    onWindowKeyDown (event) {
        if (event.defaultPrevented) {
            return;
        }

        if (event.key === 'Escape') {
            this.unselectAll();
        }
    };

    onWindowClick (event) {
        if (event.defaultPrevented) {
            return;
        }
        this.unselectAll();
    };

    onWindowTouchEnd (event) {
        if (event.defaultPrevented) {
            return;
        }
        this.unselectAll();
    };

    toggleSelection (taskId) {
        const selectedTaskIds = this.state.selectedTaskIds;
        const wasSelected = selectedTaskIds.includes(taskId);

        const newTaskIds = (() => {
            // Task was not previously selected
            // now will be the only selected item
            if (!wasSelected) {
                return [taskId];
            }

            // Task was part of a selected group
            // will now become the only selected item
            if (selectedTaskIds.length > 1) {
                return [taskId];
            }

            // task was previously selected but not in a group
            // we will now clear the selection
            return [];
        })();

        this.setState({
            selectedTaskIds: newTaskIds,
        });
    };

    toggleSelectionInGroup (taskId) {
        const selectedTaskIds = this.state.selectedTaskIds;
        const index = selectedTaskIds.indexOf(taskId);

        // if not selected - add it to the selected items
        if (index === -1) {
            this.setState({
                selectedTaskIds: [...selectedTaskIds, taskId],
            });
            return;
        }

        // it was previously selected and now needs to be removed from the group
        const shallow = [...selectedTaskIds];
        shallow.splice(index, 1);
        this.setState({
            selectedTaskIds: shallow,
        });
    };

    // This behaviour matches the MacOSX finder selection
    multiSelectTo (newTaskId) {
        const updated = multiSelect(
            this.state.entities,
            this.state.selectedTaskIds,
            newTaskId,
        );

        if (updated == null) {
            return;
        }

        this.setState({
            selectedTaskIds: updated,
        });
    };

    unselect () {
        this.unselectAll();
    };

    unselectAll () {
        this.setState({
            selectedTaskIds: [],
        });
    };

    handleFilesList (images) {
        this.setState({
            entities: initialAutoData(images)
        });
    }

    render () {
        const { classes } = this.props;
        const entities = this.state.entities;
        const selected = this.state.selectedTaskIds;
        if (entities === null) {
            return (<Alert severity="info" style={{marginBottom: 20, borderRadius: 12,}}>Gallery images fetching is in progress, Please wait...</Alert>)
        } else {
            return (
                <DragDropContext onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}>
                    <Root>
                        {entities.columnOrder.map((columnId) => {
                            return (
                                <List
                                    brand={this.props.brand}
                                    removeImg={this.props.removeImg}
                                    isCombineEnabled={true}
                                    internalScroll
                                    key={columnId}
                                    listId={columnId}
                                    listType="CARD"
                                    selectedTaskIds={selected}
                                    tasks={getTasks(entities, columnId)}
                                    draggingTaskId={this.state.draggingTaskId}
                                    toggleSelection={this.toggleSelection}
                                    toggleSelectionInGroup={this.toggleSelectionInGroup}
                                />
                            )
                        })}
                    </Root>
                </DragDropContext>
            );
        }
    }
}

export default withStyles(useStyles)(DNDApp);