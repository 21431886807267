import { Edit3, Eye, EyeOff, Upload } from 'react-feather';
import React, { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import Message from "./common/Message";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2.5),
    backgroundColor: '#E7EAF3',
  },
  profileHeader: {
    position: "relative",
    '& img': {
      display: 'block',
      width: '100%',
      borderRadius: 12,
      objectFit: 'cover',
      height: 200,
      '@media (min-width:1440px)': {
        height: 285,
      },
      '@media (max-width:576px)': {
        height: 120,
      }
    },
    marginTop: theme.spacing(4),
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    position: "absolute",
    bottom: 10,
    right: 10,
    color: theme.palette.primary.main,
    '@media (max-width:576px)': {
      minWidth: '45px',
      height: '45px',
    },
    '& i': {
      fontStyle: 'normal',
      position: 'relative',
      top: 1,
      marginLeft: theme.spacing(1),
      '@media (max-width:576px)': {
        display: 'none',
      }
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
    }
  },
  profileBox: {
    position: "absolute",
    bottom: -110,
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    '& img': {
      width: 130,
      height: 130,
      borderRadius: '50%',
      border: `${theme.palette.primary.main} 3px solid`,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(2.5),
      '@media (min-width:1440px)': {
        width: 165,
        height: 165,
      },
      '@media (max-width:576px)': {
        width: 100,
        height: 100,
      }
    }
  },
  removePic: {
    position: "absolute",
    top: theme.spacing(11),
    right: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: '40px',
    minWidth: '40px',
    height: '40px',
    lineHeight: '40px',
    borderRadius: '50%',
    padding: 0,
    '@media (min-width:1440px)': {
      top: theme.spacing(15.5),
    },
    '@media (max-width:576px)': {
      top: theme.spacing(8.5),
      width: '30px',
      minWidth: '30px',
      height: '30px',
      lineHeight: '30px',
      right: theme.spacing(3),
    }
  },
  userEmail: {
    marginTop: theme.spacing(.5),
    opacity: .65,
  },
  profileForm: {
    margin: '0 auto 0',
    maxWidth: '590px',
    borderRadius: 12,
    border: '#E7EAF3 1px solid',
    marginTop: theme.spacing(20),
    padding: theme.spacing(5),
    '@media (min-width:1440px)': {
      maxWidth: '650px',
    },
    '@media (max-width:576px)': {
      padding: theme.spacing(3),
    }
  },
  uploadBtnContainer: {
    position: 'relative',
  },
  file: {
    display: 'none',
  },
  uploadBtn: {
    width: '100%',
    height: 53.63,
    justifyContent: 'start',
    textTransform: 'initial',
    fontSize: 14,
    fontWeight: 'normal',
    backgroundColor: 'white',
    border: "#E7EAF3 1px solid",
    paddingLeft: '14px',
    paddingRight: '45px',
    '&:hover': {
      backgroundColor: 'white',
    },
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  },
  uploadBtnIcon: {
    position: 'absolute',
    top: '18px',
    right: '16px',
  },
  passIcon: {
    position: 'absolute',
    top: '34px',
    right: '16px',
    cursor: 'pointer',
  },
  authTitle: {
    margin: theme.spacing(0, 0, 2.5, 0),
  },
  subTitle: {
    margin: theme.spacing(0, 0, 2.5, 0),
  },
  colFirst: {
    paddingRight: theme.spacing(1.2),
    '@media(max-width: 576px)': {
      paddingRight: theme.spacing(0),
    }
  },
  colSecond: {
    paddingLeft: theme.spacing(1.2),
    '@media(max-width: 576px)': {
      paddingLeft: theme.spacing(0),
    }
  },
  passField: {
    position: 'relative',
  },
  gridText: {
    margin: theme.spacing(1.5, 0, 1, 0),
  },
}));

export default function UserProfile() {
  const classes = useStyles();
  const [id, setId] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  const initialFormData = Object.freeze({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    subscribe: "",
  });

  const Auth = new AuthService();

  useEffect(() => {
    let profile = Auth.getProfile();

    setId(profile.user_id);
    Auth.fetch("/api/users/user?id=" + profile.user_id)
      .then((res) => {
        if (res.ack === true) {
          setFirstName(res.user.first_name);
          setLastName(res.user.last_name);
          setEmail(res.user.email);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {
    Auth.fetch("/api/users/user-profile", {
      method: "PUT",
      body: JSON.stringify({
        id: id,
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
      }),
    })
      .then((res) => {
        if (res.ack === true) {
          setType("success");
          setLoadMessage(res.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setType("error");
          setLoadMessage(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [error, setError] = React.useState(null);
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handlePassChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const [tradeLicense, setTradeLicense] = useState('');
  const [uploadPassport, setUploadPassport] = useState('');
  const [uploadLogo, setUploadLogo] = useState('');

  const handleTradeLicense = (e) => {
    const image = e.target.files[0];
    setTradeLicense(imageFile => (image));
  }

  const handleUploadPassport = (e) => {
    const image = e.target.files[0];
    setUploadPassport(imageFile => (image));
  }

  const handleUploadLogo = (e) => {
    const image = e.target.files[0];
    setUploadLogo(imageFile => (image));
  }

  const [formData, updateFormData] = React.useState(initialFormData);
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography variant="h1">Qizai Wheel Profile</Typography>
      <Divider className={classes.divider} />
      <div className={classes.profileHeader}>
        <img variant="rounded" src="../../profile-header.jpg" alt="Qizai Wheel Profile" />
        <Button variant="contained" color="primary" component="label" disableElevation className={classes.uploadBtn}>
          <Upload size={16} />
          <i>upload header</i>
          <input type="file" hidden />
        </Button>
        <div className={classes.profileBox}>
          <img alt="Qizai Wheel" src="../../user.jpg" />
          <Button variant="contained" color="primary" component="label" disableElevation className={classes.removePic}>
            <Edit3 size={18} />
            <input type="file" hidden />
          </Button>
          <Typography variant="h2" className={classes.userName}>Qizai Wheel</Typography>
          <Typography variant="body1" className={classes.userEmail}>info@qizaiwheel.com</Typography>
        </div>
      </div>
      <div className={classes.profileForm}>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <CssTextField
              variant="outlined"
              fullWidth
              id="firstName"
              label="First Name"
              placeholder="First Name"
              name="firstName"
              required
              autoFocus
              value={firstName}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CssTextField
              variant="outlined"
              fullWidth
              id="lastName"
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
              required
              value={lastName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CssTextField
              variant="outlined"
              fullWidth
              id="email"
              label="Your Email"
              placeholder="Your Email"
              name="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.passField}>
              <CssTextField
                variant="outlined"
                fullWidth
                id="password"
                label="Password"
                placeholder="6+ characters required"
                name="password"
                autoComplete="password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handlePassChange('password')}
                error={emailError === "" ? false : true}
                helperText={emailError}
              />
              <div className={classes.passIcon} onClick={handleClickShowPassword}>
                {values.showPassword ? <Eye size={16} /> : <EyeOff size={16} />}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.uploadBtnContainer}>
              <input accept="image/*" className={classes.file} id="tradeLicense" type="file" onChange={handleTradeLicense} />
              <label htmlFor="tradeLicense">
                <Button variant="contained" component="span" className={classes.uploadBtn} disableElevation>
                  {tradeLicense.name ? tradeLicense.name : 'Uplaod Trade License'}
                </Button>
              </label>
              <Upload className={classes.uploadBtnIcon} size={16} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.uploadBtnContainer}>
              <input accept="image/*" className={classes.file} id="uploadPassport" type="file" onChange={handleUploadPassport} />
              <label htmlFor="uploadPassport">
                <Button variant="contained" component="span" className={classes.uploadBtn} disableElevation>
                  {uploadPassport.name ? uploadPassport.name : 'Uplaod passport and Visa Copy'}
                </Button>
              </label>
              <Upload className={classes.uploadBtnIcon} size={16} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.colFirst}>
            <CssTextField
              variant="outlined"
              fullWidth
              id="cName"
              label="Company Name"
              placeholder="Qizai Wheel"
              name="cName"
              autoComplete="cName"
              onChange={handleChange}
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.colSecond}>
            <CssTextField
              variant="outlined"
              fullWidth
              id="telNo"
              label="Tel No"
              placeholder="+92 341 056 6466"
              name="telNo"
              autoComplete="telNo"
              onChange={handleChange}
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.colFirst}>
            <CssTextField
              variant="outlined"
              fullWidth
              id="contactPersonName"
              label="Contact Person Name"
              placeholder="Ammar Hanif"
              name="contactPersonName"
              autoComplete="contactPersonName"
              onChange={handleChange}
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.colSecond}>
            <CssTextField
              variant="outlined"
              fullWidth
              id="contactPersonMobile"
              label="Contact Person Mobile"
              placeholder="+92 341 056 6466"
              name="contactPersonMobile"
              autoComplete="contactPersonMobile"
              onChange={handleChange}
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={12}>
            <CssTextField
              variant="outlined"
              fullWidth
              id="location"
              label="Location / Address"
              placeholder="Islamabad"
              name="location"
              autoComplete="location"
              onChange={handleChange}
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.uploadBtnContainer}>
              <input accept="image/*" className={classes.file} id="uploadLogo" type="file" onChange={handleUploadLogo} />
              <label htmlFor="uploadLogo">
                <Button variant="contained" component="span" className={classes.uploadBtn} disableElevation>
                  {uploadLogo.name ? uploadLogo.name : 'Upload Logo'}
                </Button>
              </label>
              <Upload className={classes.uploadBtnIcon} size={16} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              color="primary"
              onClick={handleSubmit}
              className={classes.submit}
            >
              update profile
            </Button>
          </Grid>
        </Grid>
      </div>
      <Message type={type} msg={loadMessage} />
    </React.Fragment>
  );
}
