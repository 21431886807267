import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Menu, TextField } from "@material-ui/core";
import { Monitor, Save, Trash2, Upload } from 'react-feather';
import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import AuthService from "./auth/AuthService";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import Divider from '@material-ui/core/Divider';
import { Editor } from "react-draft-wysiwyg";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import HeaderImage from "./HeaderImage";
import InputLabel from '@material-ui/core/InputLabel';
import LogBox from "./LogBox";
import MenuItem from '@material-ui/core/MenuItem';
import Message from "./common/Message";
import Select from '@material-ui/core/Select';
import SubImagesUpload from "./SubImagesUpload";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from "@material-ui/core/Typography";
import clsx from 'clsx';
import config from "./../config";
import draftToHtml from "draftjs-to-html";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#343A40',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7EAF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7EAF3',
        borderWidth: '1px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7EAF3',
        borderWidth: '1px',
      },
    },
  },
})(TextField);

const useStyles = createStyles((theme) => ({
  section: {
    width: 490,
    height: '300px',
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 20,
  },
  subSectionHeader: {
    marginTop: 30,
  },
  divider: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
    backgroundColor: '#E7EAF3',
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '@media (max-width:576px)': {
      minWidth: '45px',
      height: '45px',
    },
    '& i': {
      fontStyle: 'normal',
      position: 'relative',
      top: 1,
      marginLeft: theme.spacing(1),
      '@media (max-width:576px)': {
        display: 'none',
      }
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
    }
  },
  headerImg: {
    maxWidth: "100%",
    display: "block",
    height: 300,
    borderRadius: 12,
    objectFit: "cover",
    '@media (max-width:576px)': {
      width: '100%',
      height: 'auto',
    }
  },
  blogForm: {
    marginTop: theme.spacing(5),
  },
  icon: {
    borderRadius: 4,
    width: 20,
    height: 20,
    marginLeft: 2,
    position: "relative",
    top: -1,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#B14F1E',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#B14F1E',
    },
  },
  formControlLabel: {
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
    '& .MuiButtonBase-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
    }
  },
  btnGroup: {
    marginTop: theme.spacing(4),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    '@media (max-width:576px)': {
      width: '50%',
    }
  },
  cancelBtn: {
    marginRight: theme.spacing(1),
    '@media (max-width:576px)': {
      width: '50%',
    }
  },
  colorList: {
    marginRight: "5px",
    borderRadius: "2px",
    border: "0.5px solid",
    width: "20px",
    height: "10px"
  },
  selectOptions: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E7EAF3!important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'blue',
      },
    },
  },
  btnPreview: {
    backgroundColor: "#964B00",
    marginRight: "15px",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#643200",
    }
  },
  space: {
    height: 30
  },
  texArea: {
    width: '100%',
    height: '120px!important',
  }
}));

class AddCarSaleClass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: Number(props.computedMatch.params.id)
        ? props.computedMatch.params.id
        : 0,

      slug: "",
      title: "",
      titleError: "",
      price: "",
      priceError: "",
      expiry: new Date().toISOString().substring(0, 10),
      expiryError: "",
      year: new Date().getFullYear(),
      yearError: "",
      mileage: "",
      mileageError: "",
      engineType: "",
      engineTypeError: "",
      gearType: "",
      gearTypeError: "",
      location: "",
      locationError: "",
      condition: "",
      conditionError: "",
      color: "",
      colorError: "",
      model: "",
      modelError: "",
      make: "",
      makeError: "",
      driveType: "",
      driveTypeError: "",
      doors: "",
      doorsError: "",
      cylinder: "",
      cylinderError: "",
      engine: "",
      rpm: "",
      torque: "",
      transmission: "",
      dimension: "",
      fuel_tank_capacity: "",
      seating_capacity: "",
      front_suspension: "",
      rear_suspension: "",
      tyres: "",
      speedometer: "",
      power_steering: "",
      abs: "",
      vsc: "",
      urethane_steering_wheel: "",
      audio: "",
      second_row_seats: "",
      glove_box: "",
      rear_differential: "",
      telescopic_steering: "",
      ba: "",
      ebd: "",
      fabric_seats: "",
      benchtype_front_seat: "",
      third_row_seats: "",
      atrc: "",
      hac: "",
      cup_holder: "",
      dual_front_air_bags: "",
      halogen_headlamps: "",
      spear_wheel: "",
      rear_bumper_chrome: "",
      sixteen_inch_steel_wheel: "",
      front_gril_chrome: "",
      mudguards: "",
      snorkel_air_filter: "",
      rear_pintel_hook: "",
      outside_rear_view_mirrors: "",
      rear_fog_lamps: "",

      headerImage: "",
      loadMessage: "",
      type: "",

      editorState: EditorState.createEmpty(),
      editorStateHTML: "",
    };

    this.msgInterval = null;
    this.refSubImagesUpload = React.createRef();
    this.refHeaderImage = React.createRef();
    this.refLogBox = React.createRef();
    this.progressInterval = null;
    this.handleFilesList = this.handleFilesList.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.msgLoader = this.msgLoader.bind(this);
    this.inProcess = this.inProcess.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.setBoxMessage = this.setBoxMessage.bind(this);
    this.Auth = new AuthService();
  }

  setBoxMessage(msg) {
    this.refLogBox.current.pushMessage(msg);
  }

  inProcess(value) {
    if (value === 0) {
      this.setState(
        (state) => ({
          inProcess: (state.inProcess -= 1),
        }),
        () => {
          console.log(this.state.inProcess);
        }
      );
    } else {
      this.setState(
        (state) => ({
          inProcess: (state.inProcess += 1),
        }),
        () => {
          console.log(this.state.inProcess);
        }
      );
    }
  }

  handleFilesList() {
    this.msgLoader("Loading Car Sale...");
    console.log("inside handleFilesList ");
  }


  slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  componentDidMount() {
    this.setState({ loader: true });
    this.handleFilesList();
    this.handleClickOpen();
  }

  handlePreview = () => {
    window.open(`${config.domain}/car-sales/${this.state.slug}`, "_blank");
  };


  handleClickOpen = () => {
    this.setState({
      open: true,
      title: "",
      price: "",
    });

    if (this.state.id > 0) {
      this.Auth.fetch(`/api/common/car-sale?id=${this.state.id}`)
        .then((res) => {
          let that = this;
          if (res.ack === true) {
            this.setState({
              title: res.car_sale.title,
              slug: res.car_sale.slug,
              price: res.car_sale.price,
              location: res.car_sale.location,
              expiry: new Date(res.car_sale.expiry_date).toISOString().substring(0, 10),
              year: res.car_sale.year,
              mileage: res.car_sale.mileage,
              engineType: res.car_sale.engine_type,
              gearType: res.car_sale.gear_type,
              condition: res.car_sale.condition,
              color: res.car_sale.color,
              make: res.car_sale.make,
              model: res.car_sale.model,
              driveType: res.car_sale.drive_type,
              doors: res.car_sale.doors,
              cylinder: res.car_sale.cylinder,
              engine: res.car_sale.engine,
              rpm: res.car_sale.rpm,
              torque: res.car_sale.torque,
              transmission: res.car_sale.transmission,
              dimension: res.car_sale.dimension,
              fuel_tank_capacity: res.car_sale.fuel_tank_capacity,
              seating_capacity: res.car_sale.seating_capacity,
              front_suspension: res.car_sale.front_suspension,
              rear_suspension: res.car_sale.rear_suspension,
              tyres: res.car_sale.tyres,
              speedometer: res.car_sale.speedometer,
              power_steering: res.car_sale.power_steering,
              abs: res.car_sale.abs,
              vsc: res.car_sale.vsc,
              urethane_steering_wheel: res.car_sale.urethane_steering_wheel,
              audio: res.car_sale.audio,
              second_row_seats: res.car_sale.second_row_seats,
              glove_box: res.car_sale.glove_box,
              rear_differential: res.car_sale.rear_differential,
              telescopic_steering: res.car_sale.telescopic_steering,
              ba: res.car_sale.ba,
              ebd: res.car_sale.ebd,
              fabric_seats: res.car_sale.fabric_seats,
              benchtype_front_seat: res.car_sale.benchtype_front_seat,
              third_row_seats: res.car_sale.third_row_seats,
              atrc: res.car_sale.atrc,
              hac: res.car_sale.hac,
              cup_holder: res.car_sale.cup_holder,
              dual_front_air_bags: res.car_sale.dual_front_air_bags,
              halogen_headlamps: res.car_sale.halogen_headlamps,
              spear_wheel: res.car_sale.spear_wheel,
              rear_bumper_chrome: res.car_sale.rear_bumper_chrome,
              sixteen_inch_steel_wheel: res.car_sale.sixteen_inch_steel_wheel,
              front_gril_chrome: res.car_sale.front_gril_chrome,
              mudguards: res.car_sale.mudguards,
              snorkel_air_filter: res.car_sale.snorkel_air_filter,
              rear_pintel_hook: res.car_sale.rear_pintel_hook,
              outside_rear_view_mirrors: res.car_sale.outside_rear_view_mirrors,
              rear_fog_lamps: res.car_sale.rear_fog_lamps,
              headerImage: res.car_sale.header_image,
            });

            if (res.car_sale.content) {
              const parsedEditorState = res.car_sale.content;
              const editorState1 = EditorState.push(
                this.state.editorState,
                convertFromRaw(parsedEditorState),
                "update-state"
              );
              let currentContentAsHTML = draftToHtml(
                res.car_sale.content
              );
              this.setState({
                editorState: editorState1,
                editorStateHTML: currentContentAsHTML,
              });
            }


            this.refSubImagesUpload.current.resetForm();
            this.refSubImagesUpload.current.handleFilesList(
              JSON.parse(res.car_sale.images),
              JSON.parse(res.car_sale.images)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  msgLoader(msg, time = false) {
    clearInterval(this.msgInterval);
    this.setState({ loadMessage: msg });
    if (time) {
      this.msgInterval = setInterval(() => {
        this.setState({ loadMessage: "" });
      }, time);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("inside handle submit");
    if (this.state.title === "") {
      this.setState({
        titleError: "Required",
      });
      return;
    } else {
      this.setState({
        titleError: "",
      });
    }

    if (this.state.location === "") {
      this.setState({
        locationError: "Required",
      });
      return;
    } else {
      this.setState({
        locationError: "",
      });
    }

    if (this.state.expiry === "") {
      this.setState({
        expiryError: "Required",
      });
      return;
    } else {
      this.setState({
        expiryError: "",
      });
    }

    if (this.state.id > 0) {
      this.Auth.fetch("/api/common/car-sale", {
        method: "PUT",
        body: JSON.stringify({
          title: this.state.title,
          id: this.state.id,
          expiry_date: this.state.expiry,
          location: this.state.location,
          content: JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
          images: this.refSubImagesUpload.current.state.imageSorting,
          price: this.state.price,
          year: this.state.year,
          mileage: this.state.mileage,
          engine_type: this.state.engineType,
          gear_type: this.state.gearType,
          location: this.state.location,
          condition: this.state.condition,
          color: this.state.color,
          make: this.state.make,
          model: this.state.model,
          drive_type: this.state.driveType,
          doors: this.state.doors,
          cylinder: this.state.cylinder,
          engine: this.state.engine,
          rpm: this.state.rpm,
          torque: this.state.torque,
          transmission: this.state.transmission,
          dimension: this.state.dimension,
          fuel_tank_capacity: this.state.fuel_tank_capacity,
          seating_capacity: this.state.seating_capacity,
          front_suspension: this.state.front_suspension,
          rear_suspension: this.state.rear_suspension,
          tyres: this.state.tyres,
          speedometer: this.state.speedometer,
          power_steering: this.state.power_steering,
          abs: this.state.abs,
          vsc: this.state.vsc,
          urethane_steering_wheel: this.state.urethane_steering_wheel,
          audio: this.state.audio,
          second_row_seats: this.state.second_row_seats,
          glove_box: this.state.glove_box,
          rear_differential: this.state.rear_differential,
          telescopic_steering: this.state.telescopic_steering,
          ba: this.state.ba,
          ebd: this.state.ebd,
          fabric_seats: this.state.fabric_seats,
          benchtype_front_seat: this.state.benchtype_front_seat,
          third_row_seats: this.state.third_row_seats,
          atrc: this.state.atrc,
          hac: this.state.hac,
          cup_holder: this.state.cup_holder,
          dual_front_air_bags: this.state.dual_front_air_bags,
          halogen_headlamps: this.state.halogen_headlamps,
          spear_wheel: this.state.spear_wheel,
          rear_bumper_chrome: this.state.rear_bumper_chrome,
          sixteen_inch_steel_wheel: this.state.sixteen_inch_steel_wheel,
          front_gril_chrome: this.state.front_gril_chrome,
          mudguards: this.state.mudguards,
          snorkel_air_filter: this.state.snorkel_air_filter,
          rear_pintel_hook: this.state.rear_pintel_hook,
          outside_rear_view_mirrors: this.state.outside_rear_view_mirrors,
          rear_fog_lamps: this.state.rear_fog_lamps,
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            this.setState({
              type: "success",
              loadMessage: res.message,
            });
            // setTimeout(() => {
            //   window.location = "/blogs";
            // }, 1000);
          } else {
            this.setState({
              type: "error",
              loadMessage: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.Auth.fetch("/api/common/car-sale", {
        method: "POST",
        body: JSON.stringify({
          title: this.state.title,
          slug: this.slugify(this.state.title),
          expiry_date: this.state.expiry,
          location: this.state.location,
          content: JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
          price: this.state.price,
          year: this.state.year,
          mileage: this.state.mileage,
          engine_type: this.state.engineType,
          gear_type: this.state.gearType,
          location: this.state.location,
          condition: this.state.condition,
          color: this.state.color,
          make: this.state.make,
          model: this.state.model,
          drive_type: this.state.driveType,
          doors: this.state.doors,
          cylinder: this.state.cylinder,
          engine: this.state.engine,
          rpm: this.state.rpm,
          torque: this.state.torque,
          transmission: this.state.transmission,
          dimension: this.state.dimension,
          fuel_tank_capacity: this.state.fuel_tank_capacity,
          seating_capacity: this.state.seating_capacity,
          front_suspension: this.state.front_suspension,
          rear_suspension: this.state.rear_suspension,
          tyres: this.state.tyres,
          speedometer: this.state.speedometer,
          power_steering: this.state.power_steering,
          abs: this.state.abs,
          vsc: this.state.vsc,
          urethane_steering_wheel: this.state.urethane_steering_wheel,
          audio: this.state.audio,
          second_row_seats: this.state.second_row_seats,
          glove_box: this.state.glove_box,
          rear_differential: this.state.rear_differential,
          telescopic_steering: this.state.telescopic_steering,
          ba: this.state.ba,
          ebd: this.state.ebd,
          fabric_seats: this.state.fabric_seats,
          benchtype_front_seat: this.state.benchtype_front_seat,
          third_row_seats: this.state.third_row_seats,
          atrc: this.state.atrc,
          hac: this.state.hac,
          cup_holder: this.state.cup_holder,
          dual_front_air_bags: this.state.dual_front_air_bags,
          halogen_headlamps: this.state.halogen_headlamps,
          spear_wheel: this.state.spear_wheel,
          rear_bumper_chrome: this.state.rear_bumper_chrome,
          sixteen_inch_steel_wheel: this.state.sixteen_inch_steel_wheel,
          front_gril_chrome: this.state.front_gril_chrome,
          mudguards: this.state.mudguards,
          snorkel_air_filter: this.state.snorkel_air_filter,
          rear_pintel_hook: this.state.rear_pintel_hook,
          outside_rear_view_mirrors: this.state.outside_rear_view_mirrors,
          rear_fog_lamps: this.state.rear_fog_lamps,
        }),
      })
        .then((res) => {
          if (res.ack === true) {
            this.setState({
              type: "success",
              loadMessage: res.message,
            });
            if (res.record_id > 0) {
              setTimeout(() => {
                window.location = "/car-sales/" + res.record_id;
              }, 1000);
            }
            else {
              setTimeout(() => {
                window.location = "/car-sales";
              }, 1000);
            }
          } else {
            this.setState({
              type: "error",
              loadMessage: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState: editorState,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.pageRoot}>
        <Typography variant="h3">General Car Details</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="make">Make</InputLabel>
              <Select
                className={classes.selectOptions}
                label="make"
                labelId="make"
                id="make"
                value={this.state.make}
                onChange={(e) => {
                  this.setState({
                    make: e.target.value
                  });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Toyota"> Toyota </MenuItem>
                <MenuItem value="Volkswagen"> Volkswagen </MenuItem>
                <MenuItem value="Daimler"> Daimler </MenuItem>
                <MenuItem value="Ford Motor"> Ford Motor </MenuItem>
                <MenuItem value="Honda"> Honda </MenuItem>
                <MenuItem value="General Motors"> General Motors </MenuItem>
                <MenuItem value="BMW"> BMW </MenuItem>
                <MenuItem value="SAIC"> SAIC </MenuItem>
                <MenuItem value="FAW Group"> FAW Group </MenuItem>
                <MenuItem value="Hyundai"> Hyundai </MenuItem>
                <MenuItem value="Dongfeng"> Dongfeng </MenuItem>
                <MenuItem value="Nissan"> Nissan </MenuItem>
                <MenuItem value="BAIC Group"> BAIC Group </MenuItem>
                <MenuItem value="GAC Group"> GAC Group </MenuItem>
                <MenuItem value="Kia"> Kia </MenuItem>
                <MenuItem value="Renault"> Renault </MenuItem>
                <MenuItem value="Geely"> Geely </MenuItem>
                <MenuItem value="Volvo"> Volvo </MenuItem>
                <MenuItem value="Tata"> Tata </MenuItem>
                <MenuItem value="Tesla"> Tesla </MenuItem>
                <MenuItem value="Suzuki"> Suzuki </MenuItem>
                <MenuItem value="Mazda"> Mazda </MenuItem>
                <MenuItem value="Subaru"> Subaru </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CssTextField
              id="model"
              variant="outlined"
              label="Model"
              value={this.state.model}
              name="model"
              fullWidth
              placeholder="Model"
              onChange={(e) => {

                this.setState({
                  model: e.target.value
                });
              }}

            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="year">Year {this.state.year} </InputLabel>
              <Select
                className={classes.selectOptions}
                label="Year"
                value={this.state.year}
                onChange={(e) => {
                  this.setState({
                    year: e.target.value
                  });
                }}
                labelId="year"
                id="year"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {(function (rows, i, len) {
                  while (++i <= len) {
                    rows.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
                  }
                  return rows;
                })([], 1970, new Date().getFullYear())}

              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CssTextField
              id="kilometer"
              variant="outlined"
              label="Kilometer"
              value=""
              name="kilometer"
              fullWidth
              placeholder="kilometer"
              onChange={(e) => {
              }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextareaAutosize
              className={classes.texArea}
              fullWidth
              aria-label="maximum height"
              placeholder="Maximum 4 rows"
              defaultValue=""
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextareaAutosize
              className={classes.texArea}
              fullWidth
              aria-label="maximum height"
              placeholder="Maximum 4 rows"
              defaultValue=""
            />
          </Grid>
        </Grid>
        <div className={classes.space}></div>
        <Typography variant="h3">Price & Trade IN</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="priceAed"
              type="number"
              variant="outlined"
              label="Price (AED)"
              value={this.state.price}
              name="price"
              fullWidth
              placeholder="Price AED"
              onChange={(e) => {
                this.setState({
                  price: e.target.value
                });
              }}
              error={this.state.priceError === "" ? false : true}
              helperText={this.state.priceError}

            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="exportPrice"
              type="number"
              variant="outlined"
              label="Export Price"
              value={this.state.price}
              name="exportPrice"
              fullWidth
              placeholder="Export Price"
              onChange={(e) => {
                this.setState({
                  price: e.target.value
                });
              }}
              error={this.state.priceError === "" ? false : true}
              helperText={this.state.priceError}

            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="acceptTradeIn">Accept Trade In</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Accept Trade In"
                labelId="acceptTradeIn"
                id="acceptTradeIn"
                value=""
                onChange={(e) => { }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={2}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.space}></div>
        <Typography variant="h3">Specifications</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="engine-type">Regional Specs</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Regional Specs"
                labelId="regionalSpecs"
                id="regionalSpecs"
                value=""
                onChange={(e) => { }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Spec One</MenuItem>
                <MenuItem value={2}>Spec Two</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="color">Color</InputLabel>
              <Select
                className={classes.selectOptions}
                label="color"
                labelId="color"
                id="color"
                value={this.state.color}
                onChange={(e) => {
                  this.setState({
                    color: e.target.value
                  });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Red"> <div style={{ float: "left", background: "#FF0000" }} className={classes.colorList}></div> Red</MenuItem>
                <MenuItem value="Blue"> <div style={{ float: "left", background: "#0000FF" }} className={classes.colorList}></div> Blue</MenuItem>
                <MenuItem value="Green"> <div style={{ float: "left", background: "#006400" }} className={classes.colorList}></div> Green</MenuItem>
                <MenuItem value="Orange"> <div style={{ float: "left", background: "#FF6600" }} className={classes.colorList}></div> Orange</MenuItem>
                <MenuItem value="White"> <div style={{ float: "left", background: "#FFFFFF" }} className={classes.colorList}></div> White</MenuItem>
                <MenuItem value="Black"> <div style={{ float: "left", background: "#000000" }} className={classes.colorList}></div> Black</MenuItem>
                <MenuItem value="Yellow"> <div style={{ float: "left", background: "#FFFF00" }} className={classes.colorList}></div> Yellow</MenuItem>
                <MenuItem value="Purple"> <div style={{ float: "left", background: "#A020F0" }} className={classes.colorList}></div> Purple</MenuItem>
                <MenuItem value="Silver"> <div style={{ float: "left", background: "#C0C0C0" }} className={classes.colorList}></div> Silver</MenuItem>
                <MenuItem value="Brown"> <div style={{ float: "left", background: "#964B00" }} className={classes.colorList}></div> Brown</MenuItem>
                <MenuItem value="Gray"> <div style={{ float: "left", background: "#808080" }} className={classes.colorList}></div> Gray</MenuItem>
                <MenuItem value="Pink"> <div style={{ float: "left", background: "#FFC0CB" }} className={classes.colorList}></div> Pink</MenuItem>
                <MenuItem value="Olive"> <div style={{ float: "left", background: "#808000" }} className={classes.colorList}></div> Olive</MenuItem>
                <MenuItem value="Maroon"> <div style={{ float: "left", background: "#800000" }} className={classes.colorList}></div> Maroon</MenuItem>
                <MenuItem value="Violet"> <div style={{ float: "left", background: "#8F00FF" }} className={classes.colorList}></div> Violet</MenuItem>
                <MenuItem value="Charcoal"> <div style={{ float: "left", background: "#36454F" }} className={classes.colorList}></div> Charcoal</MenuItem>
                <MenuItem value="Magenta"> <div style={{ float: "left", background: "#FF00FF" }} className={classes.colorList}></div> Magenta</MenuItem>
                <MenuItem value="Bronze"> <div style={{ float: "left", background: "#CD7F32" }} className={classes.colorList}></div> Bronze</MenuItem>
                <MenuItem value="Cream"> <div style={{ float: "left", background: "#FFFDD0" }} className={classes.colorList}></div> Cream</MenuItem>
                <MenuItem value="Gold"> <div style={{ float: "left", background: "#FFD700" }} className={classes.colorList}></div> Gold</MenuItem>
                <MenuItem value="Tan"> <div style={{ float: "left", background: "#D2B48C" }} className={classes.colorList}></div> Tan</MenuItem>
                <MenuItem value="Teal"> <div style={{ float: "left", background: "#008080" }} className={classes.colorList}></div> Teal</MenuItem>
                <MenuItem value="Mustard"> <div style={{ float: "left", background: "#FFDB58" }} className={classes.colorList}></div> Mustard</MenuItem>
                <MenuItem value="Navy Blue"> <div style={{ float: "left", background: "#000080" }} className={classes.colorList}></div>Navy Blue</MenuItem>
                <MenuItem value="Coral"> <div style={{ float: "left", background: "#FF7F50" }} className={classes.colorList}></div> Coral</MenuItem>
                <MenuItem value="Burgundy"> <div style={{ float: "left", background: "#800020" }} className={classes.colorList}></div> Burgundy</MenuItem>
                <MenuItem value="Lavender"> <div style={{ float: "left", background: "#E6E6FA" }} className={classes.colorList}></div> Lavender</MenuItem>
                <MenuItem value="Mauve"> <div style={{ float: "left", background: "#E0b0FF" }} className={classes.colorList}></div> Mauve</MenuItem>
                <MenuItem value="Peach"> <div style={{ float: "left", background: "#FFE5B4" }} className={classes.colorList}></div> Peach</MenuItem>
                <MenuItem value="Rust"> <div style={{ float: "left", background: "#B7410E" }} className={classes.colorList}></div> Rust</MenuItem>
                <MenuItem value="Indigo"> <div style={{ float: "left", background: "#4B0082" }} className={classes.colorList}></div> Indigo</MenuItem>
                <MenuItem value="Ruby"> <div style={{ float: "left", background: "#E0115F" }} className={classes.colorList}></div> Ruby</MenuItem>
                <MenuItem value="Clay"> <div style={{ float: "left", background: "#CC7357" }} className={classes.colorList}></div> Clay</MenuItem>
                <MenuItem value="Cyan"> <div style={{ float: "left", background: "#00FFFF" }} className={classes.colorList}></div> Cyan</MenuItem>
                <MenuItem value="Azure"> <div style={{ float: "left", background: "#007FFF" }} className={classes.colorList}></div> Azure</MenuItem>
                <MenuItem value="Beige"> <div style={{ float: "left", background: "#F5F5DC" }} className={classes.colorList}></div> Beige</MenuItem>
                <MenuItem value="Off White"> <div style={{ float: "left", background: "#FAF9F6" }} className={classes.colorList}></div> Off White</MenuItem>
                <MenuItem value="Turquoise"> <div style={{ float: "left", background: "#30D5C8" }} className={classes.colorList}></div> Turquoise</MenuItem>
                <MenuItem value="Amber"> <div style={{ float: "left", background: "#FFBF00" }} className={classes.colorList}></div> Amber</MenuItem>
                <MenuItem value="Mint"> <div style={{ float: "left", background: "#3EB489" }} className={classes.colorList}></div> Mint</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="color">Inner Color</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Inner Color"
                labelId="innerColor"
                id="innerColor"
                value=""
                onChange={(e) => { }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Red"> <span style={{ background: "#FF0000" }} className={classes.colorList}></span> Red</MenuItem>
                <MenuItem value="Blue"> <span style={{ background: "#0000FF" }} className={classes.colorList}></span> Blue</MenuItem>
                <MenuItem value="Green"> <span style={{ background: "#006400" }} className={classes.colorList}></span> Green</MenuItem>
                <MenuItem value="Orange"> <span style={{ background: "#FF6600" }} className={classes.colorList}></span> Orange</MenuItem>
                <MenuItem value="White"> <span style={{ background: "#FFFFFF" }} className={classes.colorList}></span> White</MenuItem>
                <MenuItem value="Black"> <span style={{ background: "#000000" }} className={classes.colorList}></span> Black</MenuItem>
                <MenuItem value="Yellow"> <span style={{ background: "#FFFF00" }} className={classes.colorList}></span> Yellow</MenuItem>
                <MenuItem value="Purple"> <span style={{ background: "#A020F0" }} className={classes.colorList}></span> Purple</MenuItem>
                <MenuItem value="Silver"> <span style={{ background: "#C0C0C0" }} className={classes.colorList}></span> Silver</MenuItem>
                <MenuItem value="Brown"> <span style={{ background: "#964B00" }} className={classes.colorList}></span> Brown</MenuItem>
                <MenuItem value="Gray"> <span style={{ background: "#808080" }} className={classes.colorList}></span> Gray</MenuItem>
                <MenuItem value="Pink"> <span style={{ background: "#FFC0CB" }} className={classes.colorList}></span> Pink</MenuItem>
                <MenuItem value="Olive"> <span style={{ background: "#808000" }} className={classes.colorList}></span> Olive</MenuItem>
                <MenuItem value="Maroon"> <span style={{ background: "#800000" }} className={classes.colorList}></span> Maroon</MenuItem>
                <MenuItem value="Violet"> <span style={{ background: "#8F00FF" }} className={classes.colorList}></span> Violet</MenuItem>
                <MenuItem value="Charcoal"> <span style={{ background: "#36454F" }} className={classes.colorList}></span> Charcoal</MenuItem>
                <MenuItem value="Magenta"> <span style={{ background: "#FF00FF" }} className={classes.colorList}></span> Magenta</MenuItem>
                <MenuItem value="Bronze"> <span style={{ background: "#CD7F32" }} className={classes.colorList}></span> Bronze</MenuItem>
                <MenuItem value="Cream"> <span style={{ background: "#FFFDD0" }} className={classes.colorList}></span> Cream</MenuItem>
                <MenuItem value="Gold"> <span style={{ background: "#FFD700" }} className={classes.colorList}></span> Gold</MenuItem>
                <MenuItem value="Tan"> <span style={{ background: "#D2B48C" }} className={classes.colorList}></span> Tan</MenuItem>
                <MenuItem value="Teal"> <span style={{ background: "#008080" }} className={classes.colorList}></span> Teal</MenuItem>
                <MenuItem value="Mustard"> <span style={{ background: "#FFDB58" }} className={classes.colorList}></span> Mustard</MenuItem>
                <MenuItem value="Navy Blue"> <span style={{ background: "#000080" }} className={classes.colorList}></span>Navy Blue</MenuItem>
                <MenuItem value="Coral"> <span style={{ background: "#FF7F50" }} className={classes.colorList}></span> Coral</MenuItem>
                <MenuItem value="Burgundy"> <span style={{ background: "#800020" }} className={classes.colorList}></span> Burgundy</MenuItem>
                <MenuItem value="Lavender"> <span style={{ background: "#E6E6FA" }} className={classes.colorList}></span> Lavender</MenuItem>
                <MenuItem value="Mauve"> <span style={{ background: "#E0b0FF" }} className={classes.colorList}></span> Mauve</MenuItem>
                <MenuItem value="Peach"> <span style={{ background: "#FFE5B4" }} className={classes.colorList}></span> Peach</MenuItem>
                <MenuItem value="Rust"> <span style={{ background: "#B7410E" }} className={classes.colorList}></span> Rust</MenuItem>
                <MenuItem value="Indigo"> <span style={{ background: "#4B0082" }} className={classes.colorList}></span> Indigo</MenuItem>
                <MenuItem value="Ruby"> <span style={{ background: "#E0115F" }} className={classes.colorList}></span> Ruby</MenuItem>
                <MenuItem value="Clay"> <span style={{ background: "#CC7357" }} className={classes.colorList}></span> Clay</MenuItem>
                <MenuItem value="Cyan"> <span style={{ background: "#00FFFF" }} className={classes.colorList}></span> Cyan</MenuItem>
                <MenuItem value="Azure"> <span style={{ background: "#007FFF" }} className={classes.colorList}></span> Azure</MenuItem>
                <MenuItem value="Beige"> <span style={{ background: "#F5F5DC" }} className={classes.colorList}></span> Beige</MenuItem>
                <MenuItem value="Off White"> <span style={{ background: "#FAF9F6" }} className={classes.colorList}></span> Off White</MenuItem>
                <MenuItem value="Turquoise"> <span style={{ background: "#30D5C8" }} className={classes.colorList}></span> Turquoise</MenuItem>
                <MenuItem value="Amber"> <span style={{ background: "#FFBF00" }} className={classes.colorList}></span> Amber</MenuItem>
                <MenuItem value="Mint"> <span style={{ background: "#3EB489" }} className={classes.colorList}></span> Mint</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="title"
              variant="outlined"
              label="Title"
              value={this.state.title}
              name="title"
              fullWidth
              placeholder="Title"
              onChange={(e) => {
                this.setState({
                  title: e.target.value
                });
              }}
              error={this.state.titleError === "" ? false : true}
              helperText={this.state.titleError}
              autoFocus={true}
            />
          </Grid> */}
          {/* <Grid item lg={2} md={2} sm={6} xs={12}>
            <CssTextField
              id="expiry"
              variant="outlined"
              label="Date"
              type="date"
              value={this.state.expiry}
              name="date"
              fullWidth
              placeholder="Date"
              onChange={(e) => {
                this.setState({
                  expiry: e.target.value
                });
              }}
              error={this.state.expiryError === "" ? false : true}
              helperText={this.state.expiryError}

            />
          </Grid> */}
          {/* <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="mileage"
              type="number"
              variant="outlined"
              label="Car Mileage"
              value={this.state.mileage}
              name="carMileage"
              fullWidth
              placeholder="Car Mileage"
              onChange={(e) => {

                this.setState({
                  mileage: e.target.value
                });
              }}
              error={this.state.mileageError === "" ? false : true}
              helperText={this.state.mileageError}

            />
          </Grid> */}
          {/* <Grid item lg={4} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="engine-type">Car Engin Type</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Car Engin Type"
                labelId="engine-type"
                id="engine-type"
                value={this.state.engineType}
                onChange={(e) => {
                  this.setState({
                    engineType: e.target.value
                  });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Petrol</MenuItem>
                <MenuItem value={2}>Diesel</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item lg={4} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="gear-type">Car Gear Type</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Car Gear Type"
                labelId="gear-type"
                id="gear-type"
                value={this.state.gearType}
                onChange={(e) => {
                  this.setState({
                    gearType: e.target.value
                  });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Automatic</MenuItem>
                <MenuItem value={2}>Manual</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="standard-basic"
              variant="outlined"
              label="Location"
              value={this.state.location}
              name="location"
              fullWidth
              placeholder="Location"
              onChange={(e) => {
                this.setState({
                  location: e.target.value
                });
              }}
              error={this.state.locationError === "" ? false : true}
              helperText={this.state.locationError}

            />
          </Grid> */}
          {/* <Grid item lg={4} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="car-condition">Car Condition</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Car Condition"
                labelId="car-condition"
                id="car-condition"
                value={this.state.condition}
                onChange={(e) => {
                  this.setState({
                    condition: e.target.value
                  });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>New</MenuItem>
                <MenuItem value={2}>Used</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CssTextField
              id="standard-basic"
              variant="outlined"
              label="Wheel Size"
              value=""
              name="wheelSize"
              fullWidth
              placeholder="Wheel Size"
              onChange={(e) => { }}
              error=""
              helperText=""
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="mechanicalCondition">Mechanical Condition</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Mechanical Condition"
                labelId="mechanicalCondition"
                id="mechanicalCondition"
                value=""
                onChange={(e) => { }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Spec One</MenuItem>
                <MenuItem value={2}>Spec Two</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="bodyCondition">Body Condition</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Body Condition"
                labelId="bodyCondition"
                id="bodyCondition"
                value=""
                onChange={(e) => { }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Spec One</MenuItem>
                <MenuItem value={2}>Spec Two</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="bodyType">Body Type</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Body Type"
                labelId="bodyType"
                id="bodyType"
                value=""
                onChange={(e) => { }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Spec One</MenuItem>
                <MenuItem value={2}>Spec Two</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="seats">Seats</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Seats"
                labelId="seats"
                id="seats"
                value=""
                onChange={(e) => { }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Spec One</MenuItem>
                <MenuItem value={2}>Spec Two</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="doors">Doors</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Doors"
                labelId="doors"
                id="doors"
                value={this.state.doors}
                onChange={(e) => {
                  this.setState({
                    doors: e.target.value
                  });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={2}>2 DOORS</MenuItem>
                <MenuItem value={4}>4 DOORS</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="seats">Fuel Type</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Fuel Type"
                labelId="fuelType"
                id="fuelType"
                value=""
                onChange={(e) => { }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Spec One</MenuItem>
                <MenuItem value={2}>Spec Two</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="cylinder">Cylinder</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Cylinder"
                labelId="cylinder"
                id="cylinder"
                value={this.state.cylinder}
                onChange={(e) => {
                  this.setState({
                    cylinder: e.target.value
                  });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={2}>2 Cylinder</MenuItem>
                <MenuItem value={4}>4 Cylinder</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="drive-type">Drive Type</InputLabel>
              <Select
                className={classes.selectOptions}
                label="Drive Type"
                labelId="drive-type"
                id="drive-type"
                value={this.state.driveType}
                onChange={(e) => {
                  this.setState({
                    driveType: e.target.value
                  });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="REAR-WHEEL DRIVE">REAR-WHEEL DRIVE</MenuItem>
                <MenuItem value="FRONT-WHEEL DRIVE">FRONT-WHEEL DRIVE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CssTextField
              id="transmission"
              variant="outlined"
              label="Transmission"
              value={this.state.transmission}
              name="transmission"
              fullWidth
              placeholder="Transmission"
              onChange={(e) => {

                this.setState({
                  transmission: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CssTextField
              id="enginSize"
              variant="outlined"
              label="Engin Size (Liters)"
              value=""
              name="enginSize"
              fullWidth
              placeholder="Engin Size (Liters)"
              onChange={(e) => { }}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CssTextField
              id="horsepower"
              variant="outlined"
              label="Horsepower"
              value=""
              name="horsepower"
              fullWidth
              placeholder="Horsepower"
              onChange={(e) => { }}
            />
          </Grid>
        </Grid>
        <div className={classes.space}></div>
        <Typography variant="h3">Export Options</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CssTextField
              id="steeringSide"
              variant="outlined"
              label="Steering Side"
              value=""
              name="steeringSide"
              fullWidth
              placeholder="Steering Side"
              onChange={(e) => { }}
            />
          </Grid>
        </Grid>
        <div className={classes.space}></div>
        <Typography variant="h3">Description</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextareaAutosize
              className={classes.texArea}
              fullWidth
              placeholder="Description English"
              defaultValue=""
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextareaAutosize
              className={classes.texArea}
              fullWidth
              placeholder="Description Arabic"
              defaultValue=""
            />
          </Grid>
        </Grid>
        <div className={classes.space}></div>
        <Typography variant="h3">Warranty Or Service Record</Typography>
        <Divider className={classes.divider} />
        {this.state.id > 0 &&
          <Grid item xs={12} className={classes.gridTop}>
            <HeaderImage
              msgLoader={this.msgLoader}
              id={this.state.id}
              headerImage={this.state.headerImage}
              prefix={this.state.slug + "/header-image/"}
              urlPrefix={this.state.prefix}
              config={config}
              ref={this.refHeaderImage}
              updateApi={"car-sales-header"}
              bucket={config.car_sales_bucket}
              bucket_name={config.car_sales_bucket_name}
            />
          </Grid>
        }
        {this.state.id > 0 &&
          <SubImagesUpload
            id={this.state.id}
            setBoxMessage={this.setBoxMessage}
            inProcess={this.inProcess}
            config={config}
            msgLoader={this.msgLoader}
            prefix={this.state.slug + "/gallery/"}
            ref={this.refSubImagesUpload}
            updateApi={"car-sales-images"}
            bucket={config.car_sales_bucket}
            bucket_name={config.car_sales_bucket_name}
          />
        }
        <Divider className={classes.divider} />
        <Typography variant="h3">Gallery Goes Here</Typography>
        {/* <div className={classes.subSectionHeader}>
          <Typography variant="h3">Engine &amp; Transmission</Typography>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="engine"
              variant="outlined"
              label="Engine"
              value={this.state.engine}
              name="engine"
              fullWidth
              placeholder="Engine"
              onChange={(e) => {
                this.setState({
                  engine: e.target.value
                });
              }}

            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="rpm"
              variant="outlined"
              label="Output HP/rpm"
              value={this.state.rpm}
              name="outputHpRpm"
              fullWidth
              placeholder="Output HP/rpm"
              onChange={(e) => {

                this.setState({
                  rpm: e.target.value
                });
              }}

            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="torque"
              variant="outlined"
              label="Torque kgm/rpm"
              value={this.state.torque}
              name="torqueKgmRpm"
              fullWidth
              placeholder="Torque kgm/rpm"
              onChange={(e) => {

                this.setState({
                  torque: e.target.value
                });
              }}
              error={this.state.titleError === "" ? false : true}
              helperText={this.state.titleError}

            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="transmission"
              variant="outlined"
              label="Transmission"
              value={this.state.transmission}
              name="transmission"
              fullWidth
              placeholder="Transmission"
              onChange={(e) => {

                this.setState({
                  transmission: e.target.value
                });
              }}
            />
          </Grid>
        </Grid>
        <div className={classes.subSectionHeader}>
          <Typography variant="h3">Dimension, Capacities &amp; Supensions</Typography>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="dimension"
              variant="outlined"
              label="Dimentions (mm)"
              value={this.state.dimension}
              name="dimentions"
              fullWidth
              placeholder="Dimentions (mm)"
              onChange={(e) => {

                this.setState({
                  dimension: e.target.value
                });
              }}

            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="fuel_tank_capacity"
              variant="outlined"
              label="Fuel Tank Capacity"
              value={this.state.fuel_tank_capacity}
              name="fuelTankCapacity"
              fullWidth
              placeholder="Fuel Tank Capacity"
              onChange={(e) => {

                this.setState({
                  fuel_tank_capacity: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="seating_capacity"
              variant="outlined"
              label="Seating Capacity"
              value={this.state.seating_capacity}
              name="seatingCapacity"
              fullWidth
              placeholder="Seating Capacity"
              onChange={(e) => {

                this.setState({
                  seating_capacity: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="front_suspension"
              variant="outlined"
              label="Front Suspention"
              value={this.state.front_suspension}
              name="frontSuspention"
              fullWidth
              placeholder="Front Suspention"
              onChange={(e) => {

                this.setState({
                  front_suspension: e.target.value
                });
              }}
              error={this.state.titleError === "" ? false : true}
              helperText={this.state.titleError}

            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="rear_suspension"
              variant="outlined"
              label="Rear Suspention"
              value={this.state.rear_suspension}
              name="rearSuspention"
              fullWidth
              placeholder="Rear Suspention"
              onChange={(e) => {

                this.setState({
                  rear_suspension: e.target.value
                });
              }}

            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <CssTextField
              id="tyres"
              variant="outlined"
              label="Tyres"
              value={this.state.tyres}
              name="tyres"
              fullWidth
              placeholder="Tyres"
              onChange={(e) => {

                this.setState({
                  tyres: e.target.value
                });
              }}

            />
          </Grid>
        </Grid> */}
        <div className={classes.subSectionHeader}>
          <Typography variant="h3">Interior Features</Typography>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.speedometer}
                  name="speedometer"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      speedometer: e.target.value === "1" ? 0 : 1
                    });
                  }}
                  checked={this.state.speedometer === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Speedometer"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.power_steering}
                  name="powerSteering"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      power_steering: e.target.value === "1" ? 0 : 1
                    });
                  }}
                  checked={this.state.power_steering === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Power Steering"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.abs}
                  name="antiLockBrakingSystem"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      abs: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.abs === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Anti Lock Braking System (ABS)"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.vsc}
                  name="vehicleStabilityControl"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      vsc: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.vsc === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Vehicle Stability Control - VSC"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.urethane_steering_wheel}
                  name="spokeUrethaneSteeringWheel"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      urethane_steering_wheel: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.urethane_steering_wheel === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="4 Spoke Urethane Steering Wheel"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.audio}
                  name="audioAMFMCD"
                  color="primary"

                  onChange={(e) => {
                    this.setState({
                      audio: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.audio === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Audio/AM/FM/CD"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.second_row_seats}
                  name="secondRowSeatParallelForPassengers"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      second_row_seats: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.second_row_seats === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Second Row Seat Parallel For 6 Passengers"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.glove_box}
                  name="gloveBox"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      glove_box: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.glove_box === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Glove Box"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.rear_differential}
                  name="rearDifferential"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      rear_differential: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.rear_differential === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Rear Differential"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.telescopic_steering}
                  name="tiltTelescopic"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      telescopic_steering: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.telescopic_steering === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Tilt and Telescopic Steering Column (Manual"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.ba}
                  name="brakeAssist"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      ba: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.ba === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Brake Assist - BA"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.ebd}
                  name="electronicBrakeForceDistribution"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      ebd: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.ebd === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Electronic Brake Force Distribution - EBD"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.fabric_seats}
                  name="fabricSeats"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      fabric_seats: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.fabric_seats === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Fabric Seats"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.benchtype_front_seat}
                  name="benchtypeFrontSeat"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      benchtype_front_seat: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.benchtype_front_seat === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Benchtype Front Seat"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.third_row_seats}
                  name="thirdRowSeatPassengers"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      third_row_seats: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.third_row_seats === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Third Row Seat Parallel For 4 Passengers"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.atrc}
                  name="avtiveTractionControl"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      atrc: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.atrc === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Avtive Traction Control - ATRC"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.hac}
                  name="hillAssistControl"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      hac: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.hac === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Hill Assist Control - HAC"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.cup_holder}
                  name="cupHolder"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      cup_holder: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.cup_holder === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Cup Holder"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.dual_front_air_bags}
                  name="dualFrontAirbags"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      dual_front_air_bags: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.dual_front_air_bags === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Dual Front Airbags"
            />
          </Grid>
        </Grid>
        <div className={classes.subSectionHeader}>
          <Typography variant="h3">Exterior Controls And Security</Typography>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.halogen_headlamps}
                  name="halogenHeadlamps"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      halogen_headlamps: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.halogen_headlamps === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Halogen Headlamps"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.spear_wheel}
                  name="backDoor"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      spear_wheel: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.spear_wheel === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Back Door Mounted Spare Wheel With Lock"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.rear_bumper_chrome}
                  name="rearBumperChrome"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      rear_bumper_chrome: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.rear_bumper_chrome === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Rear Bumper Chrome"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.sixteen_inch_steel_wheel}
                  name="steelWheels"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      sixteen_inch_steel_wheel: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.sixteen_inch_steel_wheel === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label='16" Steel Wheels'
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.front_gril_chrome}
                  name="frontGrille"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      front_gril_chrome: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.front_gril_chrome === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Front Grille - Chrome"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.mudguards}
                  name="mudguards"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      mudguards: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.mudguards === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Mudguards (Front &amp; Rear)"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.snorkel_air_filter}
                  name="snorkelAirFilterCleaner"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      snorkel_air_filter: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.snorkel_air_filter === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Snorkel Air Filter Cleaner"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.rear_pintel_hook}
                  name="rearPintelHook"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      rear_pintel_hook: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.rear_pintel_hook === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Rear Pintel Hook"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.outside_rear_view_mirrors}
                  name="outsideRearViewMirrors"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      outside_rear_view_mirrors: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.outside_rear_view_mirrors === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Outside Rear View Mirrors - Chrome"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  value={this.state.rear_fog_lamps}
                  name="rearFogLamp"
                  color="primary"
                  onChange={(e) => {
                    this.setState({
                      rear_fog_lamps: e.target.value === "1" ? 0 : 1
                    });
                  }}

                  checked={this.state.rear_fog_lamps === 1 ? true : false}
                  icon={<span className={classes.icon} />}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                />}
              label="Rear Fog Lamp"
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: 30 }}>
          <Editor
            className={classes.blogEditor}
            editorState={this.state.editorState}
            wrapperClassName={classes.blogContentWrapper}
            editorClassName={classes.blogContentEditor}
            onEditorStateChange={this.onEditorStateChange}
          />
        </div>
        <div className={classes.btnGroup}>
          {this.state.id > 0 &&
            <Button
              variant="contained"
              className={classes.btnPreview}
              size="large"
              disableElevation
              onClick={this.handlePreview}
              startIcon={<Monitor size={18} />}
            >
              Preview
            </Button>
          }
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            startIcon={<Trash2 size={18} />}
            className={classes.cancelBtn}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            startIcon={<Save size={18} />}
            className={classes.saveBtn}

            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </div>
        <Message type={this.state.type} msg={this.state.loadMessage} />
        <LogBox ref={this.refLogBox} />
      </div>
    )
  };
}

export default withStyles(useStyles)(AddCarSaleClass);