import React, { Component } from 'react';

class LogBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
        close: false,
        messages: []
    }
    
    this.closeBtn = this.closeBtn.bind(this);
  }

  pushMessage (msg) {
      this.state.messages.push(msg)
  }

  scrollToBottom() {
    const scrollHeight = this.messageList.scrollHeight;
    const height = this.messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
  

  closeBtn () {
    this.setState({close: !this.state.close})
  }

  render () {
    return (
        <div class="notify-tooltip">
        <div class="notify-buttons">
            <span onClick={this.closeBtn} class="tooltip-btn tooltip-min-btn"><i class={`fa fa-${this.state.close ? 'plus' : 'minus'}`}></i></span>
        </div>
        <div style={{display: this.state.close ? 'none' : 'block'}}  ref={(div) => {
          this.messageList = div;
        }} className="notify-tooltip-content MessageList">
        
           {this.state.messages.map((obj, index) => (
                <div key={index} >
                <span class={obj.type === 'error' ? "red-color" : ""} > {obj.msg}</span><br />
                </div>
           ))}
        </div>
    </div>

    )
  }
}

export default LogBox;
